import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { AuthService } from '../../lib/auth.service';

import { endOfDay, startOfDay } from 'date-fns/esm';
import { IGraphMultiItem } from './msGraph.data';
import { IAEventInfo, IAUserInfo, ICalendarWorkplace, SpaceInfo } from './calendar.data';
import { HttpClient } from '@angular/common/http';
import { IAConfig } from '../../app-config.model';

import { environment } from '../../../environments/environment';
import { AccountInfo } from '@azure/msal-browser';
import { Location } from '@angular/common';

export class CalendarWorkplaceAzure implements ICalendarWorkplace {
    readonly CONFIG_FOLDER: string = environment.config.system.configRootFolder;
    tag: string = '[azure]';
    token: string;
    private _driveID: string;

    get account(): AccountInfo {
        return this.authSvc.activeAccount;
    }

    private _resourceAccount: { username: string, name: string };
    get resourceAccount(): { username: string, name: string } {
        return this._resourceAccount || { username: this.account?.username, name: this.account?.name };
    }
    set resourceAccount(account: { username: string, name: string }) {
        this._resourceAccount = account;
    }

    constructor(
        private http: HttpClient,
        private authSvc: AuthService,
        private location: Location) {
    }

    async getUserProfile(account: string): Promise<{ isFault: boolean, data?: IAUserInfo, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        try {
            const user: MicrosoftGraph.User = await this.authSvc.graphClient
                .api('/users/' + account)
                .select('displayName,givenName,isResourceAccount,userPrincipalName,userType')
                .get();

            console.log('[graphSvc][user] user = ', user);
            return { isFault: false, data: null };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    /*     
    async subscribeCalendarNotification(): Promise<{ isFault: boolean, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        try {
            const d: Date = new Date();
            d.setDate(d.getDate() + 3);
            const data = {
                changeType: "created,updated,deleted",
                notificationUrl: OAuthSettings.redirectUri,
                resource: 'me/mailFolders(\'Inbox\')/messages', //"/users/" + this.getActiveUserName() + "/events",
                expirationDateTime: zonedTimeToUtc(startOfDay(d), 'UTC'),
                latestSupportedTlsVersion: 'v1_2'
            };

            const ret: any = await this.authSvc.graphClient
                .api('/subscriptions')
                .post(data);

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    } 
    */

    async getRooms(): Promise<{ isFault: boolean, data?: MicrosoftGraph.Room[], error?: string | number, errorMessage?: string; }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        try {
            const ret: IGraphMultiItem<MicrosoftGraph.Room> = await this.authSvc.graphClient
                .api('/places/microsoft.graph.room')
                .top(50)
                .get();

            return { isFault: false, data: ret.value };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async getCalendarByDate(d: Date): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        const date: Date = d;
        try {
            const ret: IGraphMultiItem<MicrosoftGraph.Event> = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/calendar/calendarView')
                .query({
                    startDateTime: startOfDay(date).toISOString(),
                    endDateTime: endOfDay(date).toISOString()
                })
                //.select('subject,organizer,isOrganizer,start,end,allowNewTimeProposals,webLink,isAllDay,sensitivity')
                .orderby('start/dateTime')
                .top(50)
                .get();

            return { isFault: false, data: { calendar: ret.value.map((ev: MicrosoftGraph.Event) => new IAEventInfo(ev)), space: new SpaceInfo() } };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async getMail(event: IAEventInfo): Promise<{ isFault: boolean, data?: any, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        try {
            const ret: IGraphMultiItem<MicrosoftGraph.Event> = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/messages') // + "?$expand=microsoft.graph.eventMessage/event($filter=id eq \'" + event.id + "\')")
                .expand(`microsoft.graph.eventMessage/event($select=id,subject;$filter=id eq '${event.id}')`)
                //.filter(`id eq '${event.id}'`)
                .select('microsoft.graph.eventMessage/event')
                .top(10)
                .get();

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        console.log('[graphSvc] extend event with ', arguments);

        const endDateTime = new Date(event.endDate);
        endDateTime.setMinutes(endDateTime.getMinutes() + durationInMinute);
        console.log('[graphSvc] extend with end time = ', endDateTime);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id)
                .patch({ end: { dateTime: endDateTime.toISOString().replace('Z', ''), timeZone: 'UTC' } });

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean = false): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        console.log('[graphSvc] create adhoc event between ', startDate, endDate);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/calendar/events')
                .post({
                    subject: subject,
                    start: { dateTime: isAllDay ? startDate.toISOString().replace(/T.*Z$/, '') : startDate.toISOString().replace('Z', ''), timeZone: 'UTC' },
                    end: { dateTime: isAllDay ? endDate.toISOString().replace(/T.*Z$/, '') : endDate.toISOString().replace('Z', ''), timeZone: 'UTC' },
                    isAllDay: isAllDay
                });

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async stopEvent(event: IAEventInfo, stopDateTime: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        console.log('[graphSvc] stop event with', arguments);

        const endDateTime: Date = stopDateTime;
        endDateTime.setSeconds(0, 0);
        //if (endDateTime.getSeconds() > 0) {
        //    endDateTime.setMinutes(endDateTime.getMinutes() - 1);
        //}

        console.log('[graphSvc] stop with end time = ', endDateTime);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id)
                .patch({ end: { dateTime: endDateTime.toISOString().replace('Z', ''), timeZone: 'UTC' } });

            return { isFault: false, data: endDateTime };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        console.log('[graphSvc] cancel event with', arguments);

        if (!event.isOrganizer) {
            return await this.declineEvent(event);
        }

        try {
            //how to get the status === 202 ?
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id + '/cancel')
                .header('observe', 'response')
                .header('reponseType', 'raw') //?
                .post({ comment: comment })

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }

        /*         
        return this.http.post<void>(this.GRAPH_USER_ENDPOINT + this._account.username + '/events/' + eventID + '/cancel', { comment: comment }, { observe: 'response' }).pipe(
                    map((res: HttpResponse<void>) => {
                        return res.status === 202 ? true : false;
                    })
                ) 
        */
    }

    async declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        console.log('[graphSvc] decline event with', arguments);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id + '/decline')
                .post({});

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean; errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    async getStreamFile(relPath: string): Promise<{ isFault: boolean, data?: { content: Blob, mimeType?: string }, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        try {
            if (!this._driveID) {
                const drive: MicrosoftGraph.Drive = await this.authSvc.graphClient
                    .api(this.authSvc.isPersonalAccount ? '/users/' + this.resourceAccount.username! + '/drive' : '/sites/root')
                    .get();
                this._driveID = drive.id;
            }

            const file: MicrosoftGraph.DriveItem = await this.authSvc.graphClient
                .api((this.authSvc.isPersonalAccount ? '/drives/' + this._driveID : '/sites/' + this._driveID + '/drive') + '/root:/' + this.CONFIG_FOLDER + '/' + relPath)
                .get();

            if (!file['@microsoft.graph.downloadUrl']) {
                return { isFault: false };
            }

            const contentRaw: Blob = await this.http.get(file['@microsoft.graph.downloadUrl'], { responseType: 'blob' }).toPromise<Blob>();
            return { isFault: false, data: { content: contentRaw, mimeType: file.file.mimeType } };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async getConfig(): Promise<{ isFault: boolean, data?: IAConfig, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        //get first-layer document under root document library
        // /sites/{site-id}/drive/items/root/children
        // /drives/{drive-id}/items/root/children
        try {
            if (!this._driveID) {
                const drive: MicrosoftGraph.Drive = await this.authSvc.graphClient
                    .api(this.authSvc.isPersonalAccount ? '/users/' + this.resourceAccount.username! + '/drive' : '/sites/root')
                    .get();
                this._driveID = drive.id;
            }

            /*
            const driveItemId: string = 'root';
            const driveItems: IGraphMultiItem<MicrosoftGraph.DriveItem> = await this.authSvc.graphClient
                .api(this.authSvc.isPersonalAccount ? '/drives/' + this._driveID + '/items/' + driveItemId + '/children' : '/sites/' + this._driveID + '/drive/items/root/children')
                .get();
            
            const deskBookingFolder: MicrosoftGraph.DriveItem = driveItems.value.find(dv => dv.folder && dv.name === 'DeskBooking');
            if (!deskBookingFolder) {
                return { isFault: false };
            }

            const driveItemsUnderBookingFoler: IGraphMultiItem<MicrosoftGraph.DriveItem> = await this.authSvc.graphClient
                .api('/drives/' + drive.id + '/items/' + deskBookingFolder.id + '/children')
                .get();
            
            const configFile: MicrosoftGraph.DriveItem = driveItemsUnderBookingFoler.value.find(dv => dv.file && dv.name === 'config.json');
            */
            const configFile: MicrosoftGraph.DriveItem = await this.authSvc.graphClient
                .api((this.authSvc.isPersonalAccount ? '/drives/' + this._driveID : '/sites/' + this._driveID + '/drive') + '/root:/' + this.CONFIG_FOLDER + '/config.json')
                .get();

            if (!configFile) {
                return { isFault: true, errorMessage: 'Error: No config file' };
            }
            if (!configFile['@microsoft.graph.downloadUrl']) {
                return { isFault: true, errorMessage: 'Error: No config file download url' };
            }

            const configRaw: string = await this.http.get(configFile['@microsoft.graph.downloadUrl'], { responseType: 'text' }).toPromise<string>();
            const config: IAConfig = JSON.parse(configRaw);

            return { isFault: false, data: config };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    isUnpaired(errorCode: string | number): boolean {
        return false;
    }

    getQRCodeLink(options?: { title: string, lang: string }): Promise<string> {
        return Promise.resolve(window.location.href.substring(0, window.location.href.indexOf(this.location.path()))
            + '/?path=outlook-redirect&lang=' + options?.lang
            + '&title=' + encodeURI(options.title)
            + '&location=' + encodeURIComponent(this.account.name)
        );
    }
}
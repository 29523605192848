import { NgModule } from "@angular/core";
import { CalendarContentWrapperComponent } from "./main/calendar-content-wrapper.component";
import { CalendarContentBasicComponent } from "./main/comp/calendar-content-basic.component";
import { CalendarContentDirective } from "./main/calendar-content.directive";
import { CalendarContentService } from "./main/calendar-content.service";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { CustomPipeModule } from "src/app/pipe/custom-pipe.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarTimelineComponent } from "./sidebar/calendar-timeline.component";
import { CalendarContentDemo1Component } from "./main/comp/calendar-content-demo1.component";
import { UICompModule } from "src/app/ui-comp/ui-comp.module";

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CustomPipeModule,
        FontAwesomeModule,
        UICompModule,
        TranslateModule.forChild({
            extend: true
        })
    ],
    declarations: [
        CalendarContentDirective,
        CalendarTimelineComponent,
        CalendarContentWrapperComponent,
        CalendarContentBasicComponent,
        CalendarContentDemo1Component,
    ],
    providers: [
        CalendarContentService
    ],
    exports: [
        CalendarContentWrapperComponent,
        CalendarTimelineComponent
    ]
})
export class CalendarContentModule { }
import { APIBaseManager, API_METHOD_GET } from "../api.base";
import { HttpClient } from "@angular/common/http";


export class APIGetDevicePlatformInfoManager extends APIBaseManager<void, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);

        this._name = 'API_GetDevicePlatformInfo';
        this._method = API_METHOD_GET;
    }

    protected getRequestURL(host: string, path: void, query: void): string {
        return super.getRequestURL(host, path, query) + 'system/platformInfo';
    }
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { AccountInfo } from '@azure/msal-browser';
import { from, Subject, timer } from 'rxjs';
import { concatMap, switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from './lib/auth.service';
import { CacheService } from './lib/cache.service';
import { Helper } from './lib/helper';
import { CalendarService } from './calendar/lib/calendar.service';
import { CalendarScope } from './calendar/lib/calendar.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _appAutoUpdateNotify$ = new Subject<void>();
  private readonly _heartbeat$: Subject<void> = new Subject<void>();
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private swUpdates: SwUpdate,
    private router: Router,
    private authSvc: AuthService,
    private cacheSvc: CacheService,
    private calendarSvc: CalendarService) {
    // update html title
    document.title = environment.title;
  }

  ngOnInit(): void {
    console.log(`[app] sw enabled?: ${this.swUpdates.isEnabled}, top window?: ${Helper.isTopWindow}, scope?: ${this.calendarSvc.scope}`);

    if (this.swUpdates.isEnabled && Helper.isTopWindow) {
      //sw update available notification
      this.swUpdates.versionUpdates.pipe(
        takeUntil(this._destroying$)
      ).subscribe((event: VersionEvent) => {
        if (event.type == 'VERSION_DETECTED') {
          console.log('[app:sw] VERSION_DETECTED event: ', event);
          this._appAutoUpdateNotify$.next();
        }
      });

      //sw activated notification
      from(this.swUpdates.activateUpdate()).pipe(
        takeUntil(this._destroying$)
      ).subscribe(event => {
        console.log('[app:sw] new app version is activated', event);
      });

      //add alert when sw is unrecoverable
      this.swUpdates.unrecoverable.pipe(
        takeUntil(this._destroying$)
      ).subscribe(event => {
        console.log('[app:sw] unrecoverable: ', event.reason);
      });

      //check app update 5 minutes after page is load at first time, and then check every 60 minutes.
      timer(300000, 3600000).pipe(
        takeUntil(this._destroying$)
      ).subscribe(() => {
        if (window.navigator.onLine) {
          this.swUpdates.checkForUpdate();
        }
      });

      //if new app is available, do auto update 1 minute later.
      this._appAutoUpdateNotify$.pipe(
        switchMap(() => {
          console.log('[app] start waiting for app auto-update');
          return timer(60000);
        }),
        concatMap(() => from(this.swUpdates.activateUpdate())),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        document.location.reload();
      });
    }

    // navigate to specific path
    const url: URL = new URL(window.location.href);
    if (url.searchParams.has('path')) {
      const targetRoute: string = url.searchParams.get('path');
      console.log(`[app][${Helper.isTopWindow}] targetRoute: `, targetRoute);
      url.searchParams.delete('path');
      const searchKeyPair: { [key: string]: string } = {};

      url.searchParams.forEach((v: string, key: string) => {
        searchKeyPair[key] = v;
      });

      const fragments: { name: string, value: any }[] = url.hash.substring(1).split('&').map((pair: string) => {
        const splits = pair.split('=');
        return { name: splits[0], value: splits[1] }
      });

      fragments.forEach(fragement => searchKeyPair[fragement.name] = fragement.value);
      console.log(`[app][${Helper.isTopWindow}] search key pair: `, searchKeyPair);

      this.router.navigate(['/' + targetRoute], { queryParams: searchKeyPair });
    }

    if (Helper.isTopWindow) {
      if (this.calendarSvc.scope === CalendarScope.Mockup) {
        this.router.navigate(['/calendar']);
        return;
      }

      this.initSelfHeartbeat();

      const url: URL = new URL(window.location.href);
      const license: string = url.searchParams.get('license');
      const subpath: string = url.pathname;
      console.log(`[app] subpath: ${subpath}`);
      const queryParams = {};
      url.searchParams.forEach((v, k) => {
        queryParams[k] = v;
      });

      // to support trial license from query params.
      if (license == 'trial') {
        environment.license.trial = true;
      }

      this.authSvc.loginStatusChanged.pipe(
        takeUntil(this._destroying$)
      ).subscribe(async (status: { isLogin: boolean, account?: AccountInfo, scope: CalendarScope }) => {
        if (subpath === '' || subpath === '/' || subpath.startsWith('/login') || subpath.startsWith('/calendar')) {
          this.calendarSvc.setScope(status.scope, { account: status.account });

          if (status.isLogin) {
            console.log('[app] login change -> login', status);
            from(this.cacheSvc.saveLoginAccount(status.account.username)).subscribe(() => {
              this.router.navigate(['/calendar'], { queryParams: queryParams });
            });
          }
          else {
            console.log('[app] login change -> logout');
            this.router.navigate(['/login'], { queryParams: queryParams });
          }

          if (this.calendarSvc.scope === CalendarScope.ServiceNow) {
            this._heartbeat$.next();
          }
        }
      });
    }
  }

  private initSelfHeartbeat(): void {
    console.log('[app] is adapi support ?', (window as any).adapi ? 'true' : 'false');

    if ((window as any).adapi) {
      console.log('[app] support adapi');

      this._heartbeat$.pipe(
        switchMap(() => timer(30000, 30000)),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        (window as any).adapi.keepAlive();
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}

<div class="mt-4 text-center">
    <div *ngIf="_errorMessage">
        {{ _errorMessage }}
    </div>
    <div>
        <h2>Select a room</h2>
        <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ _room?.displayName || 'Room' }}
            </button>
            <div class="dropdown-menu">
                <div *ngFor="let place of _rooms" class="dropdown-item" (click)="selectPlace(place)">
                    {{ place.displayName }}
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5">
        <!--<button type="button" class="btn btn-primary" (click)="back()">Back</button>-->
        <button type="button" class="btn btn-primary" [disabled]="!_room" (click)="confirm()">Confirm</button>
    </div>
</div>

import { HttpClient } from "@angular/common/http";
import { APIGetLEDLightManager } from "./hardware.light.get";
import { APISetLEDLightManager } from "./hardware.light.post";
import { APIGetDeviceInfoManager } from "./deviceInfo.get";
import { APIGetDeviceModelInfoManager } from "./modelInfo.get";
import { APIGetDeviceFwInfoManager } from "./fwInfo.get";
import { APIGetDeviceNetInfoManager } from "./netInfo.get";
import { APIGetDevicePlatformInfoManager } from "./platformInfo.get";


export class APIHardwareWrapper {
    GetLight: APIGetLEDLightManager;
    SetLight: APISetLEDLightManager;

    GetDeviceInfo: APIGetDeviceInfoManager;
    GetDeviceModelInfo: APIGetDeviceModelInfoManager;
    GetDeviceFirmwareInfo: APIGetDeviceFwInfoManager;
    GetDeviceNetInfo: APIGetDeviceNetInfoManager;
    GetDevicePlatformInfo: APIGetDevicePlatformInfoManager;

    constructor(private http: HttpClient) {
        this.GetLight = new APIGetLEDLightManager(http);
        this.SetLight = new APISetLEDLightManager(http);
        this.GetDeviceInfo = new APIGetDeviceInfoManager(http);
        this.GetDeviceModelInfo = new APIGetDeviceModelInfoManager(http);
        this.GetDeviceNetInfo = new APIGetDeviceNetInfoManager(http);
        this.GetDevicePlatformInfo = new APIGetDevicePlatformInfoManager(http);
        this.GetDeviceFirmwareInfo = new APIGetDeviceFwInfoManager(http);
    }
}
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventAddDlgBaseComponent } from './event-add-dlg-base.component';
import { BOOK_DURATIONS } from '../lib/calendar.data';

@Component({
    templateUrl: './event-add-dlg-demo1.component.html',
    styleUrls: ['./event-add-dlg-demo1.component.css']
})
export class EventAddDlgDemo1Component extends EventAddDlgBaseComponent {
    constructor(protected translateSvc: TranslateService) {
        super(translateSvc);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.changeDuration(BOOK_DURATIONS[1]);
    }
}
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { BOOK_DURATIONS, CalendarAction, EventBookData, IAEventInfo } from '../lib/calendar.data';
import { CalendarHelper } from '../lib/calendar.helper';
import { IDlgFuncComponent } from './dlg-func.data';

@Component({
    template: '<div></div>'
})
export class EventAddDlgBaseComponent implements IDlgFuncComponent<{ schedule: IAEventInfo[], startDate: Date }, EventBookData>, OnInit, OnDestroy {
    readonly RECOMMAND_STARTTIME_COUNT: number = 5;
    readonly RECOMMAND_STARTTIME_GAP: number = 15;
    readonly DEFAULT_EVENT_SUBJECT: string = 'lang.word.adhocEvent';

    _startTimeRecommandOptions: { id: string, date: Date }[] = [];
    _startTimeOption: { id: string, date: Date };
    _durationRecommandOptions: number[] = BOOK_DURATIONS;

    _current: IAEventInfo;
    _next: IAEventInfo;

    _bookData: EventBookData = new EventBookData();
    _countdownStr: string;
    private readonly _countdownUnsubscribe$: Subject<void> = new Subject<void>();
    _errorMsg: string;

    title: string;
    action: CalendarAction;
    data?: { schedule: IAEventInfo[], startDate: Date, checkinRequired: boolean };
    onApprove: (action: CalendarAction, data?: EventBookData) => void;
    onReject: (action: CalendarAction, data?: EventBookData) => void;

    @ViewChild('btnClose') _btnCloseEleRef: ElementRef;

    constructor(protected translateSvc: TranslateService) { }

    ngOnInit(): void {
        const { current, next } = CalendarHelper.getCurrentAndNextEvent(this.data.schedule, { startDate: this.data.startDate, checkinRequired: this.data.checkinRequired });
        this._current = current;
        this._next = next;

        this.initStartTimeOptions();
        this.changeStartTime(this._startTimeRecommandOptions[0]);

        //countdown 5 min to auto close the dlg
        const countdownTimer: Date = new Date(0, 0, 0, 0, 5, 1, 0);
        timer(0, 1000).pipe(
            concatMap((x) => {
                countdownTimer.setSeconds(countdownTimer.getSeconds() - 1);
                const mm: number = countdownTimer.getMinutes();
                const ss: number = countdownTimer.getSeconds();

                if (!mm && !ss) {
                    this.cancel();
                }

                return this.translateSvc.get('lang.clause.closeCountdown', { '0': mm + ':' + CalendarHelper.padTime(ss) });
            }),
            map((translateTxt: string) => {
                this._countdownStr = translateTxt;
            }),
            takeUntil(this._countdownUnsubscribe$)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._countdownUnsubscribe$.next();
        this._countdownUnsubscribe$.complete();
    }

    changeStartTime(option: { id: string, date: Date }): void {
        if (this._startTimeOption !== option) {
            this._startTimeOption = option;
            this._bookData.startDate = option.date;
            //reset duration
            this._bookData.durationInMinute = 0;

            this.updateBookEndDate();
            this.updateAvailableDurations();
        }
    }

    changeDuration(duration: number): void {
        if (this._bookData.durationInMinute !== duration) {
            this._bookData.durationInMinute = duration;

            this.updateBookEndDate();
        }
    }

    protected updateBookEndDate(): void {
        const endDate: Date = new Date(this._bookData.startDate);
        endDate.setMinutes(endDate.getMinutes() + this._bookData.durationInMinute);
        this._bookData.endDate = this._bookData.durationInMinute > 0 ? CalendarHelper.getCeilDate(endDate) : endDate;
    }

    private initStartTimeOptions(): void {
        let d: Date = this.data.startDate;
        d.setSeconds(0, 0);
        this._startTimeRecommandOptions = [];
        this._startTimeRecommandOptions.push({ id: d.getTime().toString(), date: d });

        let i: number = 0;
        while (i++ < this.RECOMMAND_STARTTIME_COUNT) {
            d = CalendarHelper.getNearestTime(d, this.RECOMMAND_STARTTIME_GAP, this._next?.startDate);
            if (!d) {
                break;
            }
            this._startTimeRecommandOptions.push({ id: d.getTime().toString(), date: d });
        }
    }

    private updateAvailableDurations(): void {
        if (this._next) {
            const refDate: Date = this._startTimeOption.date;
            const offsetInMinute: number = Math.round(Math.abs(this._next.startDate.getTime() - refDate.getTime()) / 60000);
            this._durationRecommandOptions = BOOK_DURATIONS.filter(i => i <= offsetInMinute);
        }
        else {
            this._durationRecommandOptions = BOOK_DURATIONS.slice();
        }
    }

    cancel(): void {
        this.onReject(this.action, this._bookData);
        this._countdownUnsubscribe$.next();
        this._btnCloseEleRef.nativeElement.click();
    }

    reserve(): void {
        /*
        //do not happen now
        if (!this._bookData.isAllDay && new Date() > this._bookData.endDate) {
            this.translateSvc.get('lang.clause.bookExpireWarn').subscribe((res: string) => this._errorMsg = res);
            return;
        }
        */

        if (this._bookData.isAllDay) {
            this._bookData.endDate = new Date(this._bookData.startDate);
            this._bookData.endDate.setDate(this._bookData.startDate.getDate() + 1);
        }

        this._countdownUnsubscribe$.next();
        
        this.translateSvc.get(this._bookData.subject || this.DEFAULT_EVENT_SUBJECT).subscribe((res: string) => {
            this._bookData.subject = res;
            this._btnCloseEleRef.nativeElement.click();
            this.onApprove(this.action, this._bookData);
        });
    }
}
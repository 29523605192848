import { APIBaseManager, API_METHOD_POST } from "../api.base";
import { HttpClient } from "@angular/common/http";
import { LicenseInfo } from "./license.data";

export interface IGetLicenseByDeviceSignatureTxData {
    deviceSignature: any;
    license: string; //'com.iadea.workplace.room'
}

export interface IGetLicenseByDeviceSignatureRxData {
    data: {
        assignee: { playerID: string },
        licenses: {
            [category: string]: LicenseInfo;
        },
        scope: string[];
    }[];
    error: number;
    errorMessage?: string;
}

export class APIGetLicenseByDeviceSignatureManager extends APIBaseManager<void, void, IGetLicenseByDeviceSignatureTxData, IGetLicenseByDeviceSignatureRxData> {
    constructor(protected http: HttpClient) {
        super(http);

        this._name = 'API_GetLicenseByDeviceSignature';
        this._method = API_METHOD_POST;
    }

    protected getRequestURL(host: string, path: void, query: void): string {
        return `https://${host}:443/iAdeaCare/v1/licenseKeys/activate/deviceSignature`;
    }
}
<div [ngSwitch]="colorMode" class="mb-2 row">
    <label [for]="'#' + id" class="form-label col-6">{{ title | translate }}</label>
    <div class="col">
        <ng-template [ngSwitchCase]="_enumColorMode.Default">
            <input [id]="id" type="color" class="form-control" [disabled]="disabled" [ngModel]="_color"
                (ngModelChange)="changeColor($event)">
        </ng-template>
        <ng-template [ngSwitchCase]="_enumColorMode.Manual">
            <div class="input-group">
                <span class="input-group-text" [style.background-color]="_color" (click)="launchColorPicker()"></span>
                <input #colorPicker [id]="'color-' + id" type="color" class="color-input-hide"
                    [disabled]="disabled" [ngModel]="_color" (ngModelChange)="changeColor($event)">
                <input [id]="'color-text-' + id" type="text" class="form-control" [disabled]="disabled"
                    [ngModel]="_colorText" maxlength="6" (ngModelChange)="changeColor($event)">
            </div>
        </ng-template>
        <ng-template [ngSwitchCase]="_enumColorMode.Lib">
            <i class="fas fa-info-circle blue-2">TBD</i>
        </ng-template>
    </div>
</div>
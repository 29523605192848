<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="addEventModalLabel">
                {{ title | translate }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" [innerHTML]="data?.msg | translate:_msgParam">
            
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="cancel()">
                {{ 'lang.word.cancel' | translate }}
            </button>
            <button type="button" class="btn btn-primary ms-2" data-bs-dismiss="modal" (click)="confirm()">
                {{ 'lang.word.confirm' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="text-center pt-4 h-100" [ngStyle]="_bgUrl ? { 'background-image': 'url(' + _bgUrl + ')', 'background-size': 'cover'} : ''">
    <div *ngIf="!authSvc.activeAccount && !_loading; else templateLoading">
        <div *ngIf="_logo64Data" class="logo logo-lg mx-auto"
            [ngStyle]="{ 'background-image': 'url(' + _logoUrl + ')'}">
        </div>
        <div>
            <button *ngIf="_scope == _enumScope.Azure" type="button" class="btn btn-outline-light mt-5 px-4 fs-4 mx-2" [ngStyle]="{ 'color': CONFIG_FOREGROUND, 'border-color': CONFIG_FOREGROUND }" [disabled]="!_isOnline" (click)="login()">
                Microsoft
            </button>
            <div *ngIf="_errorMessage" class="mt-4 error-block">{{ _errorMessage }}</div>
        </div>  
        <div *ngIf="!_isOnline" class="mt-4 fs-3">{{ 'lang.clause.netError' | translate }}</div>
    </div>
    <ng-template #templateLoading>
        <div class="loading">
            <p>Please wait</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="addEventModalLabel">
                {{ title | translate }}
            </h5>
        </div>
        <div class="modal-body">
            <p>{{ 'lang.clause.menuCloseCountdown' | translate: _pincodeCountdownParams }}</p>
            <input #pincodeRef id="pincode" type="text" class="form-control" placeholder="PIN" maxlength="8" autofocus onlyNumber="true" [(ngModel)]="_pincodeInput" (keypress)="keyDown($event.keyCode)">
            <div *ngIf="_errorMessage" class="error-block">{{ _errorMessage | translate }}</div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                {{ 'lang.word.cancel' | translate }}
            </button>
            <button type="button" class="btn btn-primary ms-2" [disabled]="!_pincodeInput" (click)="confirm()">
                {{ 'lang.word.confirm' | translate }}
            </button>
            <button #btnClose type="button" class="btn btn-primary ms-2 d-none" data-bs-dismiss="modal"></button>
        </div>
    </div>
</div>
import { NgModule } from "@angular/core";
import { IAColorPickerComponent } from "./ia-color-picker.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IASensorCO2Component } from "./ia-sensor-co2.component";
import { IASensorPeopleComponent } from "./ia-sensor-people.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    TranslateModule.forChild({
      extend: true
    })
  ],
  providers: [
  ],
  declarations: [
    IAColorPickerComponent,
    IASensorCO2Component,
    IASensorPeopleComponent
  ],
  exports: [
    IAColorPickerComponent,
    IASensorCO2Component,
    IASensorPeopleComponent
  ]
})
export class UICompModule { }

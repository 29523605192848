<div class="sensor-wrapper">
    <div class="name">CO<span class="small">2</span></div>
    <div class="content ms-2">
        <div class="bars">
            <div *ngFor="let data of _datas" class="bar">

            </div>
        </div>
        <div class="indicator" [style.left.rem]="_indicatorOffsetX">

        </div>
    </div>
    <div class="value ms-2">
        {{ _data?.value }} ppm
    </div>
</div>
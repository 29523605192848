import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { concat, forkJoin, from, fromEvent, merge, Observable, of, Subject, timer } from 'rxjs';
import { concatMap, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { IAConfig } from '../app-config.model';
import { AuthService } from '../lib/auth.service';
import { CacheService } from '../lib/cache.service';
import { Helper } from '../lib/helper';
import { CalendarService } from './lib/calendar.service';
import { CalendarAction, CalendarScope, ErrorType, EventBookData, IAEventInfo, ICalendarAlert, Orientation, SpaceInfo, UITemplate } from './lib/calendar.data';
import { DlgFuncItem, IDlgFuncComponent } from './dlg/dlg-func.data';
import { DlgFuncDirective } from './dlg/dlg-func.directive';
import { DlgFuncService } from './dlg/dlg-func.service';
import { faTrash, faBell, faCog, faQrcode, faSignOutAlt, faExclamation, faSlidersH, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { AppConfigService } from '../app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { CalendarHelper } from './lib/calendar.helper';
import { IAdeaService } from '../lib/iadea.service';
import { ISlideFuncComponent, SlideAction, SlideFuncItem } from './slide/slide-func.data';
import { SlideFuncDirective } from './slide/slide-func.directive';
import { SlideFuncService } from './slide/slide-func.service';
import { environment } from 'src/environments/environment';
import { LicenseService } from '../lib/license.service';
import { LicenseInfo } from '../lib/iadea/license/license.data';
import { Router } from '@angular/router';

@Component({
    selector: 'ca-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, AfterViewInit {
    private readonly CALENDAR_UPDATE_SEED: number = 30;
    private readonly MIN_AVAILABLE_END_MINUTE: number = 10;
    private readonly SIDEBAR_COUNT_DOWN: number = 60;

    // icons
    readonly ICON_TRASH = faTrash;
    readonly ICON_COG = faCog;
    readonly ICON_SLIDER = faSlidersH;
    readonly ICON_QRCODE = faQrcode;
    readonly ICON_LOGOUT = faSignOutAlt;
    readonly ICON_BUILDING = faBuilding;
    readonly ICON_EXCLAMATION = faExclamation;
    readonly ICON_BELL = faBell;

    // configs
    CONFIG_HIDE_TIMELINE: boolean = AppConfigService.config.calendar.hideTimeline;
    CONFIG_HIDE_HEADER: boolean = AppConfigService.config.calendar.hideHeader;
    CONFIG_FOREGROUND: string = AppConfigService.config.theme.foreground;
    CONFIG_SHOW_QRCODE_ALWAYS: boolean = AppConfigService.config.calendar.showQRCodeAlways;
    CONFIG_ALERT_LIMIT: number = AppConfigService.config.calendar.alertLimit;
    CONFIG_LIGHTBAR_ACTIVATE: boolean = AppConfigService.config.lightbar.activate;
    CONFIG_LIGHTBAR_FREE_COLOR: string = AppConfigService.config.lightbar.available.color;
    CONFIG_LIGHTBAR_BUSY_COLOR: string = AppConfigService.config.lightbar.busy.color;
    CONFIG_LIGHTBAR_FREE_MODE: string = AppConfigService.config.lightbar.available.mode;
    CONFIG_LIGHTBAR_BUSY_MODE: string = AppConfigService.config.lightbar.busy.mode;
    CONFIG_VERSION: string = environment.version;
    CONFIG_TRIAL_STATEMENT: string = environment.license.statement;

    private renderer: Renderer2;

    // variables
    _beginDateStr: string;
    _date: Date;
    _isDateAssigned: boolean = false;
    _isTimelineExpand: boolean = false;
    _space: SpaceInfo = new SpaceInfo();
    _calendarMap: { [id: string]: IAEventInfo } = {};
    _calendar: IAEventInfo[] = [];
    _current: IAEventInfo;
    _next: IAEventInfo;
    _allowEndEvent: boolean = false;
    _isLightbarTriggered: boolean = false;

    _nextScheduleQueryCounter: number = 0;
    _activeSlideAction: SlideAction;

    _loading: boolean = false;
    _config: IAConfig;
    _logoUrl: string = environment.resource.logo;
    _bgUrl: string = environment.resource.bg;

    _account: { username: string, name: string };
    _showPopup: boolean = false;
    _popupMsg: string;
    _popMsgParams: { [paramIndex: string]: any } = {};
    private _popupMsg$ = new Subject<{ key: string, value: any[] }>();

    _alertList: ICalendarAlert[] = [];
    _isAlertRead: boolean = false;
    _newAlertCount: number = 0;

    _orientation: Orientation = Orientation.Landscape;
    _enumOrientation: typeof Orientation = Orientation;

    _isOnline: boolean = false;
    _supportLogout: boolean = environment.supportLogout && this.calendarSvc.scope !== CalendarScope.Mockup;
    _supportRoomSelection: boolean;

    _calendarScope: CalendarScope;
    _enumCalendarScope: typeof CalendarScope = CalendarScope;

    _license: LicenseInfo;
    _idTokenAuthInfo: { url: string, method: string, fragments: { name: string, value: string | number }[] };
    _isTopWindow: boolean;

    private readonly _destroying$ = new Subject<void>();

    private _slideRef: ElementRef;
    @ViewChild('slideCenterRef')
    set slideCenter(v: ElementRef) {
        if (!this._slideRef) {
            this._slideRef = v;
            if (this._slideRef.nativeElement) {
                const slideTimerStop$: Subject<void> = new Subject();
                // slide will auto-close when touch the screen area out of the slide UI.
                fromEvent(this._slideRef.nativeElement, 'hidden.bs.offcanvas').pipe(
                    takeUntil(this._destroying$)
                ).subscribe((x) => {
                    console.log('[cal] slider hide');
                    slideTimerStop$.next();
                    this.onSlideActionApprove(this._activeSlideAction);
                });

                // auto-close slider when idle time is larger than SIDEBAR_COUNT_DOWN
                merge(fromEvent(this._slideRef.nativeElement, 'mousedown'), fromEvent(this._slideRef.nativeElement, 'touchstart'), fromEvent(this._slideRef.nativeElement, 'shown.bs.offcanvas')).pipe(
                    debounceTime(200),
                    switchMap(() => timer(0, 1000).pipe(takeUntil(slideTimerStop$))),
                    takeUntil(this._destroying$)
                ).subscribe((count: number) => {
                    if (count == this.SIDEBAR_COUNT_DOWN) {
                        this._slideToggleRef.nativeElement.click();
                        slideTimerStop$.next();
                    }
                });
            }
        }
    }

    @ViewChild('dlgLaunchBtn') _dlgLaunchBtnRef: ElementRef;
    @ViewChild('dlgModalRef') _dlgModalRef: ElementRef;
    @ViewChild('slideToggleRef') _slideToggleRef: ElementRef;
    @ViewChild('btnAdvanceSlider') btnAdvanceSliderRef: ElementRef;
    @ViewChild('btnAdvance') btnAdvanceRef: ElementRef;
    @ViewChild('licenseActivatorForm') licenseActivatorFormRef: ElementRef;
    @ViewChild(DlgFuncDirective) _dlgFuncHost: DlgFuncDirective;
    @ViewChild(SlideFuncDirective) _slideFuncHost: SlideFuncDirective;

    constructor(
        private datePipe: DatePipe,
        private router: Router,
        private rendererRactory: RendererFactory2,
        private authSvc: AuthService,
        private calendarSvc: CalendarService,
        private cacheSvc: CacheService,
        private dlgFuncSvc: DlgFuncService,
        private slideFuncSvc: SlideFuncService,
        private translateSvc: TranslateService,
        private iadeaAPISvc: IAdeaService,
        private licenseSvc: LicenseService) {

        this.renderer = this.rendererRactory.createRenderer(null, null);
        this._isTopWindow = Helper.isTopWindow;
        this._idTokenAuthInfo = this.licenseSvc.getIDTokenInfo();
        this._calendarScope = this.calendarSvc.scope;

        // check if user assigns the specific date and time.
        const url: URL = new URL(window.location.href);
        const date: string = url.searchParams.get('date');
        const time: string = url.searchParams.get('time');
        this._isDateAssigned = date ? true : false;
        console.log(`[cal] set default date to ${date} ${time}`);
        this._date = CalendarHelper.setTargetDate(date, time);

        this.updateNextScheduleQueryCounter();
    }

    async ngOnInit(): Promise<void> {
        this._account = this.calendarSvc.resourceAccount;
        console.log('[cal] account: ', this._account);
        this._supportRoomSelection = this.calendarSvc.resourceAccount?.username && this.calendarSvc.account?.username !== this.calendarSvc.resourceAccount?.username;
        //this.refresh();
        this._loading = true;

        //debounce resize event to decide portrait mode.
        fromEvent(window, 'resize').pipe(
            debounceTime(300),
            takeUntil(this._destroying$)
        ).subscribe((ev: Event) => {
            this.updateOrientation();
        });

        if (this.calendarSvc.scope !== CalendarScope.Mockup) {
            concat(timer(5000), merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(debounceTime(5000))).pipe(
                takeUntil(this._destroying$)
            ).subscribe(async () => {
                console.log('[cal] online status change, online ? ', window.navigator.onLine);
                this._isOnline = window.navigator.onLine;
                if (!this._isOnline) {
                    this.showQRCode();
                    //add offline to alert
                    this.addAlert(ErrorType.Network, 'lang.clause.netError');
                    return;
                }

                this.tryDestroyDlgFunc();
                await this.getCalendar();
            });
        }

        timer(0, 1000).pipe(
            takeUntil(this._destroying$)
        ).subscribe(async () => {
            // get calendar
            if (this._nextScheduleQueryCounter-- === 0) {
                const ret: { isFault: boolean, isCalendarChanged?: boolean, lastConfigUpdateTime?: number } = await this.getCalendar();
                if (!ret.isFault) {
                    let now: Date = new Date();
                    if (ret.lastConfigUpdateTime !== undefined && (now.getTime() - ret.lastConfigUpdateTime) < 120000) {
                        console.log('[cal] init config due to config change from server');
                        this.refresh({ forceRefreshConfig: true, forceRefreshMedia: true });
                    }
                }
            }

            if (this._isDateAssigned) {
                this._date.setSeconds(this._date.getSeconds() + 1);
                this._date = new Date(this._date);
            }
            else {
                this._date = new Date();
            }

            //update schedule & lightbar if required
            if (this._date.getSeconds() === 0) {
                this.updateEventAndLightbarStatus({ reason: '0s' });
                // force refresh all page if it is 12:00 and no update more than 1 day
                this.cacheSvc.doForceUpdate(this._date);
            }
        });

        if (this._isTopWindow) {
            this.licenseSvc.onTokenUpdated.pipe(
                takeUntil(this._destroying$)
            ).subscribe((res: { isFault: boolean, token?: string, errorMessage?: string }) => {
                if (res.isFault) {
                    console.log(`[cal][${this._isTopWindow}] Update token due to "${res.errorMessage}"`);
                    this.licenseActivatorFormRef.nativeElement.submit();
                }

                this.calendarSvc.setToken(res.token);
            });

            this.licenseSvc.onLicenseUpdated.pipe(
                takeUntil(this._destroying$)
            ).subscribe((res: { license: LicenseInfo, error?: string, triggerAlert?: boolean }) => {
                console.log(`[cal][${this._isTopWindow}] onLicenseUpdated: `, res);

                if (res.error && res.triggerAlert) {
                    this.addAlert(ErrorType.Notify, 'lang.clause.noLicense', [res.error]);
                    return;
                }

                if (!this._license && res.license) {
                    this._license = res.license;
                    this.refresh({ forceRefreshConfig: true, forceRefreshCalendar: true, forceRefreshMedia: true });
                }
            });
        }

        this.init();
    }

    ngAfterViewInit(): void {
        if (this._isTopWindow) {
            // check license
            setTimeout(() => { this.licenseSvc.checkLicense(this.calendarSvc.scope); });

            fromEvent(this._dlgModalRef.nativeElement, 'shown.bs.modal').pipe(
                takeUntil(this._destroying$)
            ).subscribe((x) => {
                const ele: HTMLElement = document.querySelector('[autofocus]');
                if (ele) {
                    ele.focus();
                }
            });
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next();
        this._destroying$.complete();
    }

    launchAdvancedFeature(): void {
        this.launchDlgFunc<{ msg: string, msgParams: any[] }, boolean>(CalendarAction.AuthenticateByPin);
    }

    selectRoom(): void {
        this.router.navigate(['/room']);
    }

    showQRCode(): void {
        this.launchDlgFunc<{ lastLoginAccount: string, isOnline: boolean }, void>(CalendarAction.QRCode, { lastLoginAccount: this._account?.username, isOnline: this._isOnline });
    }

    viewAlerts(): void {
        this._isAlertRead = true;
        this.launchSlideFunc<{ alerts: ICalendarAlert[] }, { to: SlideAction }>(SlideAction.Alert, { alerts: this._alertList });
    }

    editLocalConfigs(): void {
        if (this._license) {
            this.launchSlideFunc<{ config: IAConfig }, { config?: IAConfig, useLocalConfig?: boolean, to?: SlideAction }>(SlideAction.LocalConfig, { config: this._config });
        }
    }

    logout(): void {
        if (!this._isOnline) {
            return;
        }

        this.launchDlgFunc<{ msg: string, msgParams: any[] }, void>(CalendarAction.Disconnect, { msg: 'lang.clause.disconnectWarn', msgParams: [this.calendarSvc.account?.username] });
    }

    private updateEventAndLightbarStatus(options?: { reason?: string, switchLight?: boolean, log?: boolean }): void {
        if (options.log) {
            console.log('[cal] update status by', options?.reason, this._calendar);
        }
        const { current, next } = CalendarHelper.getCurrentAndNextEvent(this._calendar, { startDate: this._date, checkinRequired: this._space?.checkinRequired });
        const tempEvent = this._current;
        this._current = current;
        this._next = next;

        if (this._current && !this._current.isAllDay) {
            const eventPassMinute: number = CalendarHelper.getEventProgressMinute(this._current, this._date);
            this._allowEndEvent = eventPassMinute >= this.MIN_AVAILABLE_END_MINUTE ? true : false;
        }

        if (!this._isLightbarTriggered || tempEvent?.id !== this._current?.id || options?.switchLight) {
            //see if to set lightbar
            this._isLightbarTriggered = true;
            this.switchLightbar();
        }
    }

    private addAlert(type: ErrorType, langKey?: string, ...args: any[]): void {
        if (this._alertList.length === this.CONFIG_ALERT_LIMIT) {
            this._alertList.shift();
        }

        let typeStr: string = 'lang.clause.alertOtherType';
        switch (type) {
            case ErrorType.Size:
            case ErrorType.Format:
                {
                    typeStr = 'lang.clause.alertFormatSizeType';
                }
                break;
            case ErrorType.Network:
                {
                    typeStr = 'lang.clause.alertConnectionType';
                }
                break;
            case ErrorType.API:
                {
                    typeStr = 'lang.clause.alertActionType';
                }
                break;
            case ErrorType.Notify:
                {
                    typeStr = 'lang.word.notification';
                }
                break;
        }

        this._alertList.unshift({
            date: new Date(this._date),
            type: typeStr,
            detail: langKey,
            detailParams: Helper.arrayToObjectWithNumberIndex(args),
            isView: false
        });

        this._newAlertCount = this._alertList.filter(a => !a.isView).length;
        this._isAlertRead = false;
    }

    private updateOrientation(): void {
        this._orientation = Helper.isPortrait() ? Orientation.Portrait : Orientation.Landscape;
    }

    onTimelineExpand(expand: boolean): void {
        this._isTimelineExpand = expand;
    }

    async onAction(event: { action: CalendarAction, data?: any }): Promise<void> {
        console.log('[cal] onAction', event);
        switch (event.action) {
            case CalendarAction.Refresh:
                {
                    this.refresh({ forceRefreshConfig: true, forceRefreshMedia: true, forceRefreshCalendar: true });
                }
                break;
            case CalendarAction.EndEvent:
                {
                    this.launchDlgFunc<{ event: IAEventInfo, actionName: string }, IAEventInfo>(CalendarAction.EndEvent, { event: event.data, actionName: 'lang.clause.endEventWarn' });
                }
                break;
            case CalendarAction.CancelEvent:
                {
                    this.launchDlgFunc<{ event: IAEventInfo, actionName: string }, IAEventInfo>(CalendarAction.CancelEvent, { event: event.data, actionName: 'lang.clause.cancelEventWarn' });
                }
                break;
            case CalendarAction.AddEvent:
                {
                    this.launchDlgFunc<{ schedule: IAEventInfo[], startDate: Date, checkinRequired: boolean }, EventBookData>(CalendarAction.AddEvent, { schedule: this._calendar, startDate: event.data?.startDate || new Date(this._date), checkinRequired: this._space.checkinRequired });
                }
                break;
            case CalendarAction.Extend:
                {
                    const param: { event: IAEventInfo, duration: number } = event.data;
                    const ret: { isFault: boolean, error?: string | number, errorMessage?: string } = await this.calendarSvc.extendEvent(param.event, param.duration);
                    if (ret.isFault) {
                        this.updatePopupMessage('lang.clause.extendEventFail', [param.event.subject, param.duration, ret.errorMessage], ErrorType.API);
                        return;
                    }

                    this.updatePopupMessage('lang.clause.extendEventPass', [param.event.subject, param.duration]);
                    this.getCalendar();
                }
                break;
            case CalendarAction.Checkin:
                {
                    try {
                        const param: IAEventInfo = event.data;
                        const ret: { isFault: boolean, error?: string | number, errorMessage?: string } = await this.calendarSvc.checkInEvent(param);
                        if (ret.isFault) {
                            this.updatePopupMessage('lang.clause.checkinEventFail', [param.subject, ret.errorMessage], ErrorType.API);
                            return;
                        }

                        this.updatePopupMessage('lang.clause.checkinEventPass', [param.subject]);
                        this.getCalendar();
                    }
                    catch (ex) {
                        console.error(ex);
                    }
                }
                break;
            case CalendarAction.Checkout:
                {
                    try {
                        const param: IAEventInfo = event.data;
                        const ret: { isFault: boolean, error?: string | number, errorMessage?: string } = await this.calendarSvc.checkOutEvent(param);
                        if (ret.isFault) {
                            this.updatePopupMessage('lang.clause.checkoutEventFail', [param.subject, ret.errorMessage], ErrorType.API);
                            return;
                        }

                        this.updatePopupMessage('lang.clause.checkoutEventPass', [param.subject]);
                        this.getCalendar();
                    }
                    catch (ex) {
                        console.error(ex);
                    }
                }
                break;
            case CalendarAction.AdjustDate:
                {
                    const param: { day: number } = event.data;
                    this._date = new Date(this._date);
                    this._date.setDate(this._date.getDate() + param.day);

                    this.refresh({ forceRefreshCalendar: true });
                }
                break;
            case CalendarAction.FindRoom:
                {
                    /*
                    const param: { resources: string[] } = event.data;
                    this._btnFindRoomRef.nativeElement.click();
                    this.calendarSvc.findFreeSpace(param.resources).subscribe((res: { availabilityView: string, scheduleID: string, currentEvent: CalendarEventInfo, nextEvent: CalendarEventInfo, freeOffsetInMinute: number }[]) => {
                        this._roomStatusList = res.sort((a, b) => {
                            return b.freeOffsetInMinute - a.freeOffsetInMinute;
                        });
                    });
                    */
                }
                break;
            case CalendarAction.Notification:
                {
                    const msg = event.data;
                    this.updatePopupMessage(msg);
                }
                break;
        }
    }

    private init(): void {
        this.updateOrientation();
        this._isOnline = window.navigator.onLine;

        /*
        this.initConfig(true).pipe(
            concatMap(() => this.getImages())
        ).subscribe((res: {
            bg: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] },
            logo: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }
        }) => {
            // no need to show error alerts on init(). do it after user login.
            this._bgUrl = res.bg.data || this._bgUrl;
            this._logoUrl = res.logo.data || this._logoUrl;

            console.log('[cal] init complete');
        });
        */
    }

    private getImages(forceRefresh: boolean = false): Observable<{
        bg: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] },
        logo: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }
    }> {
        return forkJoin({
            bg: from(this.cacheSvc.getBg(forceRefresh)),
            logo: from(this.cacheSvc.getLogo(forceRefresh))
        });
    }

    private initConfig(forceRefresh: boolean = false): Observable<IAConfig> {
        return from(this.cacheSvc.getConfig(forceRefresh)).pipe(
            map((res: { config: IAConfig, errorMsg?: string }) => {
                //always renew config instance
                this._config = new IAConfig(res.config);
                //set the language
                this.translateSvc.use(this._config.locale);
                this.CONFIG_HIDE_TIMELINE = this._config.calendar.hideTimeline;
                this.CONFIG_HIDE_HEADER = this._config.calendar.hideHeader;
                this.CONFIG_FOREGROUND = this._config.theme.foreground;
                this.CONFIG_SHOW_QRCODE_ALWAYS = this._config.calendar.showQRCodeAlways;
                this.CONFIG_ALERT_LIMIT = this._config.calendar.alertLimit;
                this.CONFIG_LIGHTBAR_ACTIVATE = this._config.lightbar.activate;
                this.CONFIG_LIGHTBAR_FREE_COLOR = this._config.lightbar.available.color;
                this.CONFIG_LIGHTBAR_BUSY_COLOR = this._config.lightbar.busy.color;
                this.CONFIG_LIGHTBAR_FREE_MODE = this._config.lightbar.available.mode;
                this.CONFIG_LIGHTBAR_BUSY_MODE = this._config.lightbar.busy.mode;

                this.setFontsize(this._config.fontsizeRatio);

                return res.config;
            })
        );
    }

    private setFontsize(fontsizeRatio: number): void {
        console.log(`[cal] update font-size to ${16 * fontsizeRatio}`);
        this.renderer.setStyle(document.documentElement, 'font-size', 16 * fontsizeRatio + 'px');
    }

    private refresh(options?: { forceRefreshConfig?: boolean, forceRefreshMedia?: boolean, forceRefreshCalendar?: boolean }): void {
        console.log(`[cal] refresh with config: ${options?.forceRefreshConfig}, media: ${options?.forceRefreshConfig}, calendar: ${options?.forceRefreshCalendar}`);
        this._loading = true;
        this._isLightbarTriggered = false;
        this._popupMsg$.unsubscribe();

        this.initConfig(options?.forceRefreshConfig).pipe(
            concatMap((config: IAConfig) => {
                // re-init popup msg destroyer
                this._popupMsg$ = new Subject<{ key: string, value: any[] }>();
                this._popupMsg$.pipe(
                    switchMap((data: { key: string, value: any[] }) => {
                        this._popMsgParams = Helper.arrayToObjectWithNumberIndex(data.value);
                        this._popupMsg = data.key;
                        this._showPopup = true;
                        return timer(config.calendar.msgPopupDuration)
                    }),
                    takeUntil(this._destroying$)
                ).subscribe(() => {
                    this._showPopup = false;
                });

                return forkJoin({
                    calendar: from(this.getCalendar(options?.forceRefreshCalendar)),
                    img: this.getImages(options?.forceRefreshMedia)
                })
            }),
        ).subscribe((res: {
            calendar: { isFault: boolean, isCalendarChanged: boolean, lastConfigUpdateTime: number },
            img: {
                bg: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] },
                logo: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }
            }
        }) => {
            this._bgUrl = res.img.bg.data || this._bgUrl;
            this._logoUrl = res.img.logo.data || this._logoUrl;

            if (res.img.bg.isFault) {
                this.addAlert(res.img.bg.errorType, res.img.bg.errorMsg, res.img.bg.errorMsgParams);
            }
            if (res.img.logo.isFault) {
                this.addAlert(res.img.logo.errorType, res.img.logo.errorMsg, res.img.logo.errorMsgParams);
            }

            this.updateEventAndLightbarStatus({ reason: 'refresh', log: true });

            this._loading = false;
        });
    }

    private updatePopupMessage(msgLangKey: string, args?: any[], alertType?: ErrorType): void {
        this._popupMsg$.next({ key: msgLangKey, value: args });

        if (alertType) {
            this.addAlert(alertType, msgLangKey, ...args);
        }
    }

    private async getCalendar(force: boolean = false): Promise<{ isFault: boolean, isCalendarChanged?: boolean, isOccupancyChanged?: boolean, lastConfigUpdateTime?: number }> {
        try {
            if (!this._account || !this._isOnline) {
                return { isFault: true };
            }

            const ret: { isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string } = await this.calendarSvc.getCalendarByDate(this._date, force);
            if (ret.isFault) {
                if (this.calendarSvc.isUnpaired(ret.error)) {
                    this.authSvc.signout();
                }

                return { isFault: true };
            }

            let isOccupancyChanged: boolean = this._space.occupancy.isChanged(ret.data.space.occupancy);
            this._space = ret.data.space;
            if (isOccupancyChanged) {
                console.log('[cal] space: ', this._space);
            }

            let isCalendarChanged: boolean = ret.data?.calendar?.length !== Object.keys(this._calendarMap).length;
            if (isCalendarChanged) {
                console.log('[cal] calendar is changed due to diff counts');
            }
            else {
                for (let ev of ret.data.calendar) {
                    if (!this._calendarMap[ev.id]) {
                        console.log('[cal] calendar is changed due to no local copy');
                        isCalendarChanged = true;
                        break;
                    }
                    else if (this._calendarMap[ev.id].etag !== ev.etag) {
                        console.log('[cal] calendar is changed due to diff etag');
                        isCalendarChanged = true;
                        break;
                    }
                }
            }

            // calendar is changed
            if (isCalendarChanged) {
                this._calendarMap = ret.data.calendar.reduce((acc, cur) => {
                    acc[cur.id] = cur;
                    return acc;
                }, {});
                this._calendar = ret.data.calendar;
                console.log('[cal] updated calendar', this._calendar);
            }

            if (isCalendarChanged || isOccupancyChanged) {
                console.log(`[cal] calendar change?: ${isCalendarChanged}, occupancy change?: ${isOccupancyChanged}`);
                this.updateEventAndLightbarStatus({ reason: 'state change', switchLight: isOccupancyChanged, log: true });
            }

            return { isFault: false, isCalendarChanged: isCalendarChanged, isOccupancyChanged: isOccupancyChanged, lastConfigUpdateTime: ret.data.lastConfigUpdateTime };
        }
        finally {
            this.updateNextScheduleQueryCounter();
        }
    }

    private switchLightbar(host: string = 'localhost'): void {
        if (environment.system.lockByIAdea && !this.iadeaAPISvc.isIAdeaDevice(navigator.userAgent)) {
            return;
        }

        console.log(`[cal] switchLightbar to ${host}, activate?: ${this.CONFIG_LIGHTBAR_ACTIVATE}, freeColor?: ${this.CONFIG_LIGHTBAR_FREE_COLOR}, busyColor?: ${this.CONFIG_LIGHTBAR_BUSY_COLOR}`);
        let token: string = '';
        of(host).pipe(
            concatMap((host: string) =>
                host === 'localhost' ? of('') : this.iadeaAPISvc.getToken(host, '').pipe(
                    map((res: { token?: string, passwordRequired: boolean, retry_timeout: number, error?: any }) => {
                        if (res.error) {
                            throw res.error;
                        }

                        token = res.token;
                        return res.token;
                    })
                )
            ),
            concatMap((token: string) => this.iadeaAPISvc.getLEDColor(host, token)),
            concatMap((res: { id: number, name: string, color: string, mode?: string }[]) => {
                let targetColor: string;
                if (!this._space.occupancy.hasOccupancySensor) {
                    targetColor = this._current ? this.CONFIG_LIGHTBAR_BUSY_COLOR : this.CONFIG_LIGHTBAR_FREE_COLOR;
                }
                else {
                    if (this._space.occupancy.isOccupied) {
                        targetColor = this._current ? '#FF0000' : '#FFBF00';
                    }
                    else {
                        targetColor = this._current ? '#FF0000' : '#00FF00';
                    }
                }

                const lightMap$: { [name: string]: Observable<{ error?: any, ledColor?: string, id?: number }> } = res.reduce((a, v) => ({
                    ...a,
                    [v.name]: this.iadeaAPISvc.setLEDColor(
                        host,
                        token,
                        v.id,
                        v.name,
                        targetColor,
                        this._current ? (this.CONFIG_LIGHTBAR_ACTIVATE ? this.CONFIG_LIGHTBAR_BUSY_MODE : 'off') : (this.CONFIG_LIGHTBAR_ACTIVATE ? this.CONFIG_LIGHTBAR_FREE_MODE : 'off'),
                        this._current ? (this.CONFIG_LIGHTBAR_BUSY_MODE === 'on' ? 1 : 0) : (this.CONFIG_LIGHTBAR_FREE_MODE === 'on' ? 1 : 0),
                        false
                    )
                }), {});

                return forkJoin(lightMap$)
            })
        ).subscribe();
    }

    private updateNextScheduleQueryCounter(): void {
        this._nextScheduleQueryCounter = Math.floor((1 + Math.random()) * this.CALENDAR_UPDATE_SEED);
    }

    private launchSlideFunc<T, R>(action: SlideAction, data?: T): void {
        console.log('[cal] launch slide ' + SlideAction[action], data);
        const func: SlideFuncItem<T> = this.slideFuncSvc.getFunctionByAction(action);
        if (!func || !this._slideFuncHost) {
            return;
        }

        this._activeSlideAction = action;
        const viewContainerRef = this._slideFuncHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(func.component);

        (<ISlideFuncComponent<T, R>>componentRef.instance).title = func.title;
        (<ISlideFuncComponent<T, R>>componentRef.instance).action = action;
        (<ISlideFuncComponent<T, R>>componentRef.instance).data = data;
        (<ISlideFuncComponent<T, R>>componentRef.instance).onApprove = this.onSlideActionApprove.bind(this);
        //(<ISlideFuncComponent<T, R>>componentRef.instance).onReject = this.onSlideReject.bind(this);
    }

    private async onSlideActionApprove(action: SlideAction, res?: any): Promise<void> {
        console.log('[cal] onSlideApprove: ', SlideAction[action], res);
        switch (action) {
            case SlideAction.LocalConfig:
                {
                    if (res) {
                        const data: { config?: IAConfig, useLocalConfig?: boolean, to?: SlideAction } = res as { config?: IAConfig, useLocalConfig?: boolean, to?: SlideAction };
                        if (data.config !== undefined || data.useLocalConfig !== undefined) {
                            from(this.cacheSvc.saveLocalConfig(data.config, data.useLocalConfig)).pipe(
                                concatMap(() => this.initConfig(!data.useLocalConfig))
                            ).subscribe((config: IAConfig) => {
                                this.switchLightbar();
                            });
                        }

                        if (data.to) {
                            this.launchSlideFunc<{ alertCount: number, license: LicenseInfo }, void>(SlideAction.Advance, { alertCount: this._newAlertCount, license: this._license });
                        }
                    }
                }
                break;
            case SlideAction.Alert:
                {
                    this._alertList.forEach(a => a.isView = true);
                    this._newAlertCount = 0;

                    const data: { to: SlideAction } = res as { to: SlideAction };
                    if (data?.to) {
                        this.launchSlideFunc<{ alertCount: number, license: LicenseInfo }, void>(SlideAction.Advance, { alertCount: this._newAlertCount, license: this._license });
                    }
                }
                break;
            case SlideAction.Advance:
                {
                    switch (res) {
                        case SlideAction.Alert:
                            {
                                this.viewAlerts();
                            }
                            break;
                        case SlideAction.LocalConfig:
                            {
                                this.editLocalConfigs();
                            }
                            break;
                        case SlideAction.SelectRoom:
                            {
                                // do something
                            }
                            break;
                        case SlideAction.Logout:
                            {
                                this.logout();
                            }
                            break;
                    }
                }
        }
    }

    private launchDlgFunc<T, R>(action: CalendarAction, data?: T): void {
        console.log('[cal] launch dlg ' + CalendarAction[action], data);
        const template: UITemplate = environment.template;
        const dlg: DlgFuncItem<T> = this.dlgFuncSvc.getFunctionByAction<T>(action, template);
        if (!dlg || !this._dlgFuncHost) {
            console.log(`[cal] no suitable dialog found for action "${action}" with template "${template}"`);
            return;
        }

        const viewContainerRef = this._dlgFuncHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(dlg.component);

        (<IDlgFuncComponent<T, R>>componentRef.instance).title = dlg.title;
        (<IDlgFuncComponent<T, R>>componentRef.instance).action = action;
        (<IDlgFuncComponent<T, R>>componentRef.instance).data = data;
        (<IDlgFuncComponent<T, R>>componentRef.instance).onApprove = this.onActionDlgApprove.bind(this);
        (<IDlgFuncComponent<T, R>>componentRef.instance).onReject = this.onDlgReject.bind(this);

        setTimeout(() => {
            this._dlgLaunchBtnRef.nativeElement.click();
        }, 0);
    }

    private tryDestroyDlgFunc(): void {
        if (!this._dlgFuncHost) {
            return;
        }

        const viewContainerRef = this._dlgFuncHost.viewContainerRef;
        viewContainerRef.clear();

        if (this._dlgModalRef.nativeElement.hasAttribute('aria-modal')) {
            this._dlgLaunchBtnRef.nativeElement.click();
        }
    }

    private async onActionDlgApprove(action: CalendarAction, data?: any): Promise<void> {
        console.log('[cal] on dlg approved = ', action, data);
        this.tryDestroyDlgFunc();

        switch (action) {
            case CalendarAction.AddEvent:
                {
                    //approve add event
                    const bookData: EventBookData = data as EventBookData;
                    const ret: { isFault: boolean, errorMessage?: string } = await this.calendarSvc.addEvent(bookData.subject, bookData.startDate, bookData.endDate, bookData.isAllDay);
                    if (!ret.isFault) {
                        this.updatePopupMessage('lang.clause.addEventPass', [bookData.subject]);
                        await this.getCalendar();
                        // this.updateEventAndLightbarStatus({ reason: 'Event add' });

                        return;
                    }

                    this.updatePopupMessage('lang.clause.addEventFail', [bookData.subject, ret.errorMessage], ErrorType.API);
                }
                break;
            case CalendarAction.CancelEvent:
                {
                    //approve cancel event
                    const event: IAEventInfo = data as IAEventInfo;
                    //if you are not the organizer of the meeting, you should decline the event, not cancel.
                    //the meeting on organizer's calendar will show that you are declined.
                    const ret: { isFault: boolean, errorMessage?: string } = await this.calendarSvc.cancelEvent(event);
                    if (!ret.isFault) {
                        this.updatePopupMessage('lang.clause.cancelEventPass', [event?.subject]);
                        await this.getCalendar();
                        // this.updateEventAndLightbarStatus({ reason: 'Event cancel' });

                        return;
                    }

                    this.updatePopupMessage('lang.clause.cancelEventFail', [event?.subject, ret.errorMessage], ErrorType.API);
                }
                break;
            case CalendarAction.EndEvent:
                {
                    //approve end event
                    const event: IAEventInfo = data as IAEventInfo;
                    //you can stop the event no matter you are the organizer of the meeting or not.
                    //the meeting length will not be affected when you stop it when you are not the organizer.
                    const ret: { isFault: boolean, data?: Date, errorMessage?: string } = await this.calendarSvc.stopEvent(event, new Date(this._date));
                    if (!ret.isFault) {
                        this.updatePopupMessage('lang.clause.endEventPass', [event?.subject, this.datePipe.transform(ret.data, 'HH:mm')]);
                        await this.getCalendar();
                        // this.updateEventAndLightbarStatus({ reason: 'Event end' });

                        return;
                    }

                    this.updatePopupMessage('lang.clause.endEventFail', [event?.subject, ret.errorMessage], ErrorType.API);
                }
                break;
            case CalendarAction.Disconnect:
                {
                    this.authSvc.signout();
                }
                break;
            case CalendarAction.AuthenticateByPin:
                {
                    if (data) {
                        // launch advanced drop-down items.
                        setTimeout(() => {
                            this.launchSlideFunc<{ alertCount: number, license: LicenseInfo }, void>(SlideAction.Advance, { alertCount: this._newAlertCount, license: this._license });
                            this.btnAdvanceSliderRef?.nativeElement.click();
                        }, 500);
                    }
                }
        }
    }

    private onDlgReject(action: CalendarAction, data?: any): void {
        this.tryDestroyDlgFunc();
    }
}
<div #timelineContainer class="timeline-block">
    <div class="timeline-header">
        <div class="expand-icon">
            <fa-icon *ngIf="_orientation === _enumOrientation.Landscape"
                [icon]="_expand ? ICON_ARROW_RIGHT : ICON_ARROW_LEFT" (click)="expandTimeline()"></fa-icon>
        </div>
        <div *ngIf="CONFIG_ENABLE_ADJUST_DATE" class="date-control">
            <fa-icon [icon]="ICON_CARET_LEFT" (click)="viewPrevDate()"></fa-icon>
            <!-- <span>{{ _date | pipeDate }}</span> -->
            <fa-icon [icon]="ICON_CARET_RIGHT" (click)="viewNextDate()"></fa-icon>
        </div>
    </div>
    <div #timelineScroller class="timeline">
        <div class="indicator">
            <div class="line" [style.top.rem]="_timeIndicatorOffset">
            </div>
        </div>
        <div class="left">
            <div *ngFor="let timelineSlot of _timelineSlotList" class="slot">
                <div class="time-str">
                    {{ timelineSlot.str }}
                </div>
            </div>
        </div>
        <div class="right">
            <div *ngFor="let timelineSlot of _timelineSlotList" class="slot" [class.overlay]="!_isOnline">
                <div class="slot-inner" [style.background]="CONFIG_TIMELINE_BG_COLOR" (click)="addEvent(timelineSlot)">
                </div>
            </div>
            <div *ngFor="let bs of _busyList; trackBy: trackBusyTimeslotFn" class="busy-slot"
                [class.highlight]="bs.source.id === _highlightBusyEvent?.source.id"
                [style.background]="bs.isInProcess ? CONFIG_TIMELINE_BLOCK_CURRENT_BG_COLOR : (bs.isExpired ? CONFIG_TIMELINE_BLOCK_EXPIRE_BG_COLOR : CONFIG_TIMELINE_BLOCK_FUTURE_BG_COLOR)"
                [style.top.rem]="bs.begin" [style.bottom.rem]="bs.end"
                [style.left]="'calc(' + bs.overlapRaio * bs.overlapOffsetLeft + '%)'"
                [style.width]="'calc(' + bs.overlapRaio * bs.overlapWidth + '%)'" [style.height.rem]="bs.end - bs.begin"
                (click)="bs.isOverlap && _expand ? highlightEvent(bs) : expandTimeline()">
                <fa-icon
                    *ngIf="_expand && CONFIG_ENABLE_FUTURE_EVENT_CANCEL && !bs.isExpired && bs.source.id !== _current?.id"
                    [icon]="ICON_FATIMES" class="icon-remove" (click)="removeEvent(bs); $event.stopPropagation()">
                </fa-icon>
                <div *ngIf="_expand" class="content" [class.overlap]="bs.isOverlap">
                    {{ bs.source?.subject | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { faPlus, faTimes, faCalendarPlus, faSyncAlt, faCalendarCheck, faCalendarXmark } from '@fortawesome/free-solid-svg-icons';
import { CacheService } from '../../../../lib/cache.service';
import { CalendarContentBaseComponent } from '../calendar-content-base.component';

@Component({
    templateUrl: './calendar-content-basic.component.html',
    styleUrls: ['./calendar-content.style.css', './calendar-content-basic.component.css']
})
export class CalendarContentBasicComponent extends CalendarContentBaseComponent {
    //icons
    readonly ICON_FAPLUS = faPlus;
    readonly ICON_FATIMES = faTimes;
    readonly ICON_CALENDAR_PLUS = faCalendarPlus;
    readonly ICON_SYNC = faSyncAlt;
    readonly ICON_CALENDAR_CHECKIN = faCalendarCheck;
    readonly ICON_CALENDAR_CHECKOUT = faCalendarXmark;

    constructor(protected cacheSvc: CacheService) {
        super(cacheSvc)
    }
}
import { IAngEnvironmentConfig } from 'src/app/app-config.model';
import { globalSetting } from './environment.global';
import { ColorPickerMode } from 'src/app/ui-comp/ia-color.data';
import { CalendarScope } from 'src/app/calendar/lib/calendar.data';

export const environment: IAngEnvironmentConfig = {
    version: globalSetting.version,
    name: 'dev',
    title: 'IAdea Booking (for ServiceNow)',
    production: false,
    scope: CalendarScope.ServiceNow,
    template: globalSetting.template,
    supportLogout: false,
    license: {
        trial: false,
        mode: 'idToken',
        server: 'api.iadea.oniadea.com',
        statement: '', //globalSetting.license.statement,
    },
    auth: {
        tenantId: '6173c853-4dfa-49f2-aa9d-955fc70b5a9f',
        appId: '08fe8597-ba05-4782-9859-33872b5b500b',
        redirectUri: 'https://dev.booking.for-workplace.com' //'https://dev.booking.for-workplace.com',
    },
    resource: {
        logo: globalSetting.resource.logo,
        bg: globalSetting.resource.bg
    },
    system: {
        lockByIAdea: true,
        colorPicker: ColorPickerMode.Manual
    },
    config: {
        locale: globalSetting.config.locale,
        pin: globalSetting.config.pin,
        dateTimeOption: {
            hour12: false,
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
            hour: "numeric",
            minute: "numeric"
        },
        background: "bg.jpg",
        logo: "logo.png",
        fontsizeRatio: globalSetting.config.fontsizeRatio,
        theme: {
            foreground: globalSetting.config.theme.foreground,
            freeColor: globalSetting.config.theme.freeColor,
            busyColor: globalSetting.config.theme.busyColor,
            timeline: {
                bgColor: globalSetting.config.theme.timeline.bgColor,
                futureEventTimeBlockColor: globalSetting.config.theme.timeline.futureEventTimeBlockColor,
                currentEventTimeBlockColor: globalSetting.config.theme.timeline.currentEventTimeBlockColor,
                expiredEventTimeBlockColor: globalSetting.config.theme.timeline.expiredEventTimeBlockColor
            }
        },
        calendar: {
            enableOnsiteBook: globalSetting.config.calendar.enableOnsiteBook,
            enableFutureEventBook: globalSetting.config.calendar.enableFutureEventBook,
            enableFutureEventCancel: globalSetting.config.calendar.enableFutureEventCancel,
            enableDateSwitch: false,
            hideTimeline: globalSetting.config.calendar.hideTimeline,
            hideHeader: globalSetting.config.calendar.hideHeader,
            showQRCodeAlways: globalSetting.config.calendar.showQRCodeAlways,
            msgPopupDuration: 10000,
            minEventDuration: 15,
            timelineIdleDuration: 5,
            alertLimit: 10
        },
        lightbar: {
            activate: true,
            available: {
                color: globalSetting.config.lightbar.available.color,
                mode: globalSetting.config.lightbar.available.mode
            },
            busy: {
                color: globalSetting.config.lightbar.busy.color,
                mode: globalSetting.config.lightbar.busy.mode
            }
        },
        resource: {
            bg: {
                sizeLimit: 4,
                supportMimeTypes: ["jpg", "jpeg", "png"]
            },
            logo: {
                sizeLimit: 0.5,
                supportMimeTypes: ["jpg", "jpeg", "png", "svg"]
            }
        },
        system: {
            configRootFolder: globalSetting.configFolderName + '/svcnow'
        },
    }
};

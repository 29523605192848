import { Injectable } from '@angular/core';
import { CalendarAction, IAEventInfo, UITemplate } from '../lib/calendar.data';
import { DlgFuncItem } from './dlg-func.data';
import { EventAddDlgBasicComponent } from './event-add-dlg-basic.component';
import { EventCancelDlgComponent } from './event-cancel-dlg.component';
import { QRCodeDlgComponent } from './qrcode-dlg.component';
import { WarningDlgComponent } from './warning-dlg.component';
import { PincodeAuthDlgComponent } from './pincode-auth-dlg.component';
import { EventAddDlgDemo1Component } from './event-add-dlg-demo1.component';

@Injectable()
export class DlgFuncService {
    private _items: DlgFuncItem<any>[] = [];

    get Funcs(): DlgFuncItem<any>[] {
        return this._items;
    }

    constructor() {
        this._items = [
            new DlgFuncItem<{ schedule: IAEventInfo[], startDate: Date }>(EventAddDlgBasicComponent, CalendarAction.AddEvent, UITemplate.Basic, 'lang.word.createEvent'),
            new DlgFuncItem<{ schedule: IAEventInfo[], startDate: Date }>(EventAddDlgDemo1Component, CalendarAction.AddEvent, UITemplate.Demo1, 'lang.word.createEvent'),
            new DlgFuncItem<{ event: IAEventInfo, actionName: string }>(EventCancelDlgComponent, CalendarAction.CancelEvent, UITemplate.Basic, 'lang.word.cancelEvent'),
            new DlgFuncItem<{ event: IAEventInfo, actionName: string }>(EventCancelDlgComponent, CalendarAction.EndEvent, UITemplate.Basic, 'lang.word.endEvent'),
            new DlgFuncItem<{ lastLoginAccount: string, isOnline: boolean }>(QRCodeDlgComponent, CalendarAction.QRCode, UITemplate.Basic, 'lang.word.qrcode'),
            new DlgFuncItem<{ msg: string, msgParams: any[] }>(WarningDlgComponent, CalendarAction.Disconnect, UITemplate.Basic, 'lang.word.logout'),
            new DlgFuncItem<boolean>(PincodeAuthDlgComponent, CalendarAction.AuthenticateByPin, UITemplate.Basic, 'lang.clause.enterConfigMenu')
        ];
    }

    getFunctionByAction<T>(action: CalendarAction, template: UITemplate = UITemplate.Basic): DlgFuncItem<T> {
        return this._items.find(item => item.action === action && item.template === template);
    }
}
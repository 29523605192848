import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IDlgFuncComponent } from "./dlg-func.data";
import { CalendarAction } from "../lib/calendar.data";
import { CacheService } from "src/app/lib/cache.service";
import { IAConfig } from "src/app/app-config.model";
import { Helper } from "src/app/lib/helper";
import { Subject, from, fromEvent, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    templateUrl: 'pincode-auth-dlg.component.html'
})
export class PincodeAuthDlgComponent implements IDlgFuncComponent<{ msg: string, msgParams: any[] }, boolean>, OnInit, AfterViewInit, OnDestroy {
    private readonly MENU_COUNT_DOWN: number = 15;
    private readonly KEYCODE_RETURN: number = 13;
    
    title: string;
    action: CalendarAction;
    data?: { msg: string; msgParams: any[]; };
    onApprove: (action: CalendarAction, data?: boolean) => void;
    onReject: (action: CalendarAction, data?: boolean) => void;

    private readonly _unsubscribe$: Subject<void> = new Subject<void>();

    _pincode: number;
    _pincodeInput: number;
    _errorMessage: string;
    _pincodeCountdownParams: { [paramIndex: string]: any } = {};

    @ViewChild('pincodeRef') pincodeRef: ElementRef;
    @ViewChild('btnClose') btnCloseRef: ElementRef;

    constructor(private cacheSvc: CacheService) {}

    ngOnInit(): void {
        from(this.cacheSvc.getConfig()).subscribe((res: { config: IAConfig, errorMsg?: string }) => {
            this._pincode = res.config?.pin;
        });

        timer(0, 1000).pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((count: number) => {
            this._pincodeCountdownParams = Helper.arrayToObjectWithNumberIndex([this.MENU_COUNT_DOWN - count]);
            if (count == this.MENU_COUNT_DOWN) {
                this.close();
            }
        });
    }

    ngAfterViewInit(): void {
        fromEvent(this.pincodeRef.nativeElement, 'keydown').pipe(
            takeUntil(this._unsubscribe$)
        )
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    keyDown(keyCode: number): void {
        if (keyCode === this.KEYCODE_RETURN) {
            this.confirm();
        }
    }

    confirm(): void {
        this._errorMessage = null;
        if (this._pincode != this._pincodeInput) {
            this._errorMessage = 'lang.clause.pincodeAuthError';
        }
        else {
            this.close();
            this.onApprove(this.action, true);
        }
    }

    close(): void {
        this.btnCloseRef.nativeElement.click();
    }
}
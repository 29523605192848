import { Injectable } from '@angular/core';
import { CalendarContentItem } from './calendar-content.data';
import { CalendarContentBasicComponent } from './comp/calendar-content-basic.component';
import { UITemplate } from '../../lib/calendar.data';
import { CalendarContentDemo1Component } from './comp/calendar-content-demo1.component';

@Injectable()
export class CalendarContentService {
    private _items: CalendarContentItem[] = [];

    get Items(): CalendarContentItem[] {
        return this._items;
    }

    constructor() {
        this._items = [
            new CalendarContentItem(CalendarContentBasicComponent, UITemplate.Basic),
            new CalendarContentItem(CalendarContentDemo1Component, UITemplate.Demo1)
        ];
    }

    getContentItem(template: string): CalendarContentItem {
        return this._items.find(item => item.contentTemplate === template);
    }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventAddDlgBaseComponent } from './event-add-dlg-base.component';

@Component({
    templateUrl: './event-add-dlg-basic.component.html',
    styleUrls: ['./event-add-dlg-basic.component.css']
})
export class EventAddDlgBasicComponent extends EventAddDlgBaseComponent {
    constructor(protected translateSvc: TranslateService) {
        super(translateSvc)
    }
}
import { Type } from '@angular/core';
import { CalendarAction, UITemplate } from '../lib/calendar.data';

export class DlgFuncItem<T> {
    constructor(public component: Type<any>, public action: CalendarAction, public template: UITemplate, public title: string, public data?: T) { }
}

export interface IDlgFuncComponent<T, R> {
    title: string;
    action: CalendarAction;
    data?: T;

    onApprove: (action: CalendarAction, data?: R) => void;
    onReject: (action: CalendarAction, data?: R) => void;
}
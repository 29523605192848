
export const OAuthSettings = {
    authority: 'https://login.microsoftonline.com/common',
    scopes: [
        "user.read",
        "calendars.readwrite",
        //"calendars.readwrite.shared",
        "files.read.all",
        "Sites.Read.All",
        //"Mail.Read"
    ],
    apiBaseUrl: 'https://graph.microsoft.com/v1.0/'
};

export const PERSONAL_TENANTID: string = '9188040d-6c67-4c5b-b112-36a304b66dad';
import { APIBaseManager, API_METHOD_POST } from "../api.base";
import { HttpClient } from "@angular/common/http";
import { LicenseInfo } from "./license.data";

export interface IGetLicenseTxData {
    id_token: string
}

export interface IGetLicenseRxData {
    data: {
        assignee: { playerID: string },
        licenses: {
            [category: string]: LicenseInfo;
        },
        scope: string[];
    }[];
    error: number;
    errorMessage?: string;
}

export class APIGetLicenseManager extends APIBaseManager<void, void, IGetLicenseTxData, IGetLicenseRxData> {
    constructor(protected http: HttpClient) {
        super(http);

        this._name = 'API_PostLicense';
        this._method = API_METHOD_POST;
    }

    protected getRequestURL(host: string, path: void, query: void): string {
        return `https://${host}:443/iAdeaCare/v1/licenseKeys/assignee/openID`;
    }
}
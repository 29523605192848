import { LicenseInfo } from "src/app/lib/iadea/license/license.data";
import { CalendarAction, IAEventInfo, SpaceInfo } from "../../lib/calendar.data";
import { IAConfig } from "src/app/app-config.model";
import { Type } from "@angular/core";

export interface ICalendarContent {
    config: IAConfig;
    license: LicenseInfo;
    events: IAEventInfo[];
    currentEvent: IAEventInfo;
    nextEvent: IAEventInfo;
    date: Date;
    isOnline: boolean;
    isUpdating: boolean;
    allowEventEnd: boolean;
    allowEventCheckin: boolean;
    allowEventCheckout: boolean;
    account: { username: string, name: string };
    logo: string;
    space: SpaceInfo;
    onActionComplete: (action: CalendarAction, data?: any) => void;
}

export class CalendarContentItem {
    constructor(public component: Type<any>, public contentTemplate: string) { }
}
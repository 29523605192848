<div class="app" [ngStyle]="_bgUrl ? { 'background-image': 'url(' + _bgUrl + ')', 'background-size': 'cover'} : ''">
    <div *ngIf="CONFIG_TRIAL_STATEMENT" class="statement">{{ CONFIG_TRIAL_STATEMENT }}</div>
    <div *ngIf="!CONFIG_HIDE_HEADER" class="header" [class.invisible]="!_account">
        <i class="ms-auto"></i>
        <div *ngIf="CONFIG_SHOW_QRCODE_ALWAYS || (_account && !_isOnline)">
            <fa-icon [icon]="ICON_QRCODE" class="fa-icon ms-4" (click)="showQRCode()"></fa-icon>
        </div>
        <div class="dropdown position-relative ms-4">
            <fa-icon [icon]="ICON_COG" class="fa-icon inactive" (click)="launchAdvancedFeature()">
            </fa-icon>
            <div #btnAdvanceSlider id="btnAdvanceSlider" data-bs-toggle="offcanvas" data-bs-target="#slideCenter"
                aria-controls="offcanvasRight" style="width:1px;height:1px"></div>
            <div #btnAdvance id="btnAdvance" data-bs-toggle="dropdown" aria-expanded="false"
                style="width:1px;height:1px"></div>
            <div *ngIf="_alertList.length > 0 && !_isAlertRead" class="alert-counter">
                <fa-icon [icon]="ICON_EXCLAMATION"></fa-icon>
            </div>
            <ul class="dropdown-menu" aria-labelledby="btnAdvance">
                <li class="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#slideCenter"
                    aria-controls="offcanvasRight" (click)="viewAlerts()">
                    <fa-icon [icon]="ICON_BELL" class="me-2"></fa-icon>
                    {{ 'lang.word.notification' | translate }}
                    <span>({{ _newAlertCount }})</span>
                </li>
                <li class="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#slideCenter"
                    aria-controls="offcanvasRight" [class.disabled]="!_license" (click)="editLocalConfigs()">
                    <fa-icon [icon]="ICON_SLIDER" class="me-2"></fa-icon>
                    {{ 'lang.word.config' | translate }}
                </li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li *ngIf="_supportRoomSelection" class="dropdown-item" (click)="selectRoom()">
                    <fa-icon [icon]="ICON_BUILDING" class="me-2"></fa-icon>
                    {{ 'Select another room' | translate }}
                </li>
                <li *ngIf="_supportLogout" class="dropdown-item" (click)="logout()">
                    <fa-icon [icon]="ICON_LOGOUT" class="me-2"></fa-icon>
                    {{ 'lang.word.logout' | translate }}
                </li>
                <li class="dropdown-item fst-italic fs-6 text-muted">
                    {{ 'lang.word.version' | translate }} {{ CONFIG_VERSION }}
                </li>
            </ul>
        </div>
    </div>
    <div class="body container-fluid" [style.color]="CONFIG_FOREGROUND"
        [style.height]="CONFIG_HIDE_HEADER ? 'calc(100vh)' : 'calc(100vh - 60px)'">
        <div class="row">
            <div class="col-12"
                [ngClass]="_orientation === _enumOrientation.Portrait || CONFIG_HIDE_TIMELINE ? 'col-12' : (_isTimelineExpand ? 'col-md-8' : 'col-md-9')">
                <div>
                    <ca-calendar-content-wrapper [events]="_calendar" [currentEvent]="_current" [nextEvent]="_next"
                        [license]="_license" [account]="_account" [logo]="_logoUrl" [allowEventEnd]="_allowEndEvent"
                        [isOnline]="_isOnline" [updating]="_loading" [date]="_date" [config]="_config" [space]="_space"
                        (onAction)="onAction($event)">
                    </ca-calendar-content-wrapper>
                </div>
            </div>
            <div *ngIf="!CONFIG_HIDE_TIMELINE" class="col-12"
                [ngClass]="_orientation === _enumOrientation.Portrait ? 'col-12 mt-2' : (_isTimelineExpand ? 'col-md-4' : 'col-md-3')">
                <ca-timeline [calendar]="_calendar" [currentEvent]="_current" [date]="_date" [isOnline]="_isOnline"
                    [license]="_license" [updating]="_loading" [orientation]="_orientation" [config]="_config" [space]="_space"
                    (expanded)="onTimelineExpand($event)" (onAction)="onAction($event)">
                </ca-timeline>
            </div>
        </div>
        <div class="popup-msg" [class.show]="_showPopup">
            {{ _popupMsg | translate: _popMsgParams }}
        </div>
    </div>
</div>


<!-- global functions -->
<button #dlgLaunchBtn type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#dlgFuncModalHost">
</button>
<div #dlgModalRef id="dlgFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template dlg-func-host></ng-template>
</div>
<div #slideCenterRef id="slideCenter" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="slideCenterLabel">
    <ng-template slide-func-host></ng-template>
    <button #slideToggleRef type="button" class="d-none" data-bs-dismiss="offcanvas"></button>
</div>

<!-- iframe to query license -->
<div *ngIf="_isTopWindow && _calendarScope !== _enumCalendarScope.Mockup" class="d-none">
    <iframe id="license-iframe" name="license-iframe" sandbox="allow-scripts allow-same-origin allow-forms">
    </iframe>
    <form #licenseActivatorForm action="{{ _idTokenAuthInfo.url }}" target="license-iframe"
        method="{{ _idTokenAuthInfo.method }}">
        <input *ngFor="let fragment of _idTokenAuthInfo.fragments" type="hidden" name="{{fragment.name}}"
            value="{{fragment.value}}">
    </form>
</div>
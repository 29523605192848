import { Component, Input } from '@angular/core';
import { IAConfig } from '../../../app-config.model';
import { BOOK_DURATIONS, CalendarAction, IAEventInfo, SpaceInfo } from '../../lib/calendar.data';
import { AppConfigService } from '../../../app-config.service';
import { CacheService } from '../../../lib/cache.service';
import { Helper } from 'src/app/lib/helper';
import { LicenseInfo } from 'src/app/lib/iadea/license/license.data';
import { ICalendarContent } from './calendar-content.data';
import { CalendarHelper } from '../../lib/calendar.helper';

@Component({
    selector: 'ca-content',
    template: '<div></div>'
})
export class CalendarContentBaseComponent implements ICalendarContent {
    protected readonly ADHOC_EVENT_INTERVALS: number[] = BOOK_DURATIONS;

    // config options
    CONFIG_VANCANCY_BG_COLOR: string = Helper.hexToRgba(AppConfigService.config.theme.freeColor);
    CONFIG_BUSY_BG_COLOR: string = Helper.hexToRgba(AppConfigService.config.theme.busyColor);
    CONFIG_ENABLE_ONSITE_BOOK: boolean = AppConfigService.config.calendar.enableOnsiteBook ?? true;
    CONFIG_ENABLE_ONSITE_EXTEND: boolean = AppConfigService.config.calendar.enableOnsiteExtend ?? true;
    CONFIG_ENABLE_ONSITE_CANCEL_OR_STOP: boolean = AppConfigService.config.calendar.enableOnsiteCancelOrStop ?? true;

    // translation
    _freeUntilParams: { [paramIndex: string]: any } = {};

    _textOverflowLength: number = 25;
    _availableEventIntervals: number[] = [];
    _isExpandOn: boolean = false;
    protected _lastCurrentEvent: IAEventInfo;

    // inputs
    @Input() events: IAEventInfo[] = [];

    _currentEvent: IAEventInfo;
    @Input()
    set currentEvent(v: IAEventInfo) {
        this._lastCurrentEvent = this._currentEvent;
        this._currentEvent = v;
        this.updateCurrentEvent();
    }
    get currentEvent(): IAEventInfo {
        return this._currentEvent;
    }

    private _nextEvent: IAEventInfo;
    @Input()
    set nextEvent(v: IAEventInfo) {
        this._nextEvent = v;
        this.updateNextEvent();
    }
    get nextEvent(): IAEventInfo {
        return this._nextEvent;
    }

    @Input() allowEventEnd: boolean = false;
    @Input() allowEventCheckin: boolean = false;
    @Input() allowEventCheckout: boolean = false;
    @Input() isOnline: boolean = false;
    @Input() isUpdating: boolean = false;
    @Input() date: Date;
    @Input() license: LicenseInfo;
    @Input() account: { username: string, name: string };
    @Input() logo: string;
    @Input() space: SpaceInfo;

    protected _config: IAConfig;
    @Input('config')
    set config(v: IAConfig) {
        this._config = v;
        if (this._config) {
            this.CONFIG_ENABLE_ONSITE_BOOK = this._config.calendar!.enableOnsiteBook ?? true;
            this.CONFIG_ENABLE_ONSITE_EXTEND = this._config.calendar!.enableOnsiteExtend ?? true;
            this.CONFIG_ENABLE_ONSITE_CANCEL_OR_STOP = this._config.calendar!.enableOnsiteCancelOrStop ?? true;
            this.CONFIG_VANCANCY_BG_COLOR = Helper.hexToRgba(this._config.theme!.freeColor);
            this.CONFIG_BUSY_BG_COLOR = Helper.hexToRgba(this._config.theme!.busyColor);
        }
    }

    constructor(protected cacheSvc: CacheService) { }

    onActionComplete: (action: CalendarAction, data?: any) => void;

    expandEvent(): void {
        this._isExpandOn = !this._isExpandOn;
        if (this._isExpandOn) {
            this.calculateAvailableExtendDurations();
        }
    }

    checkinEvent(): void {
        this.onActionComplete?.(CalendarAction.Checkin, this._currentEvent || this._nextEvent);
    }

    checkoutEvent(): void {
        this.onActionComplete?.(CalendarAction.Checkout, this._currentEvent);
    }

    addEvent(): void {
        this.onActionComplete?.(CalendarAction.AddEvent);
    }

    extendEvent(duration: number): void {
        this.currentEvent ? this.onActionComplete?.(CalendarAction.Extend, { event: this.currentEvent, duration: duration }) : this.onActionComplete?.(CalendarAction.AddEvent, duration);
    }

    stopEvent(): void {
        this.onActionComplete?.(CalendarAction.EndEvent, this.currentEvent);
    }

    cancelEvent(): void {
        this.onActionComplete?.(CalendarAction.CancelEvent, this.currentEvent);
    }

    refresh(): void {
        if (this.isUpdating || !this.license) {
            return;
        }

        this.onActionComplete?.(CalendarAction.Refresh);
    }

    isCheckinRequired(): boolean {
        if (this.space.checkinRequired && this.currentEvent && !this._currentEvent.isCheckin) {
            return true;
        }

        if (this.space.checkinRequired && this._nextEvent && !this._nextEvent.isCheckin) {
            const now: Date = new Date();
            const nextEventStartDateTime: Date = this._nextEvent.startDate;
            const checkinBeforeEventDateTime: Date = new Date(nextEventStartDateTime);
            checkinBeforeEventDateTime.setMinutes(checkinBeforeEventDateTime.getMinutes() - this.space.checkinBeforeMeetingMinute);
            if (CalendarHelper.isTimeInRange(now, checkinBeforeEventDateTime, nextEventStartDateTime, true)) {
                return true;
            }
        }

        return false;
    }

    private calculateAvailableExtendDurations(): void {
        this._availableEventIntervals = [];
        if (this.nextEvent) {
            const refDate: Date = this.currentEvent?.endDate ?? new Date(this.date);
            const offsetInMinute: number = Math.round(Math.abs(this.nextEvent.startDate.getTime() - refDate.getTime()) / 60000);
            this._availableEventIntervals = this.ADHOC_EVENT_INTERVALS.filter(i => i <= offsetInMinute);
        }
        else {
            this._availableEventIntervals = this.ADHOC_EVENT_INTERVALS.slice();
        }
    }

    protected updateCurrentEvent(): void { }

    private updateNextEvent(): void {
        this.calculateAvailableExtendDurations();
        if (this.nextEvent) {
            this._freeUntilParams = Helper.arrayToObjectWithNumberIndex([this.cacheSvc.timeFormatter?.format(this.nextEvent.startDate)]);
        }
    }
}
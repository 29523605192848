import { Component, OnInit } from "@angular/core";
import { CalendarService } from "../calendar/lib/calendar.service";
import { Router } from "@angular/router";
import { CacheService } from "../lib/cache.service";
import { from } from "rxjs";

@Component({
    templateUrl: './room-config.component.html'
})
export class RoomConfigComponent implements OnInit {
    _rooms: microsoftgraph.Room[] = [];
    _room: microsoftgraph.Room;
    _errorMessage: string;

    constructor(private router: Router, private cacheSvc: CacheService, private calendarSvc: CalendarService) { }

    async ngOnInit(): Promise<void> {
        const roomRet: { isFault: boolean; data?: microsoftgraph.Room[]; errorMessage?: string; } = await this.calendarSvc.getRooms();
        if (roomRet.isFault) {
            this._errorMessage = roomRet.errorMessage;
        }

        this._rooms = roomRet.data;
    }

    selectPlace(room: microsoftgraph.Room): void {
        this._room = room;
    }

    back(): void {
        this.router.navigate(['/login']);
    }

    confirm(): void {
        if (this._room) {
            this.calendarSvc.resourceAccount = {
                username: this._room.emailAddress,
                name: this._room.displayName,
                id: this._room.id
            };

            from(this.cacheSvc.saveResourceAccount(this._room.emailAddress)).subscribe(() => {
                this.router.navigate(['/calendar']);
            });
        }
    }
}
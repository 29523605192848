import { Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CalendarAction, IAEventInfo, SpaceInfo } from "../../lib/calendar.data";
import { LicenseInfo } from "src/app/lib/iadea/license/license.data";
import { IAConfig } from "src/app/app-config.model";
import { CalendarContentDirective } from "./calendar-content.directive";
import { CalendarContentItem, ICalendarContent } from "./calendar-content.data";
import { CalendarContentService } from "./calendar-content.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'ca-calendar-content-wrapper',
    template: `<ng-template ca-content-host></ng-template>`
})
export class CalendarContentWrapperComponent implements OnInit {
    private _componentRef: ComponentRef<any> | undefined;

    private _account: { username: string, name: string };
    @Input()
    set account(v: { username: string, name: string }) {
        this._account = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).account = v;
        }
    }

    private _logoUrl: string;
    @Input()
    set logo(v: string) {
        this._logoUrl = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).logo = v;
        }
    }

    private _events: IAEventInfo[];
    @Input()
    set events(v: IAEventInfo[]) {
        this._events = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).events = v;
        }

    }

    private _currentEvent: IAEventInfo;
    @Input()
    set currentEvent(v: IAEventInfo) {
        this._currentEvent = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).currentEvent = v;
        }

    }

    private _nextEvent: IAEventInfo;
    @Input()
    set nextEvent(v: IAEventInfo) {
        this._nextEvent = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).nextEvent = v;
        }

    }

    private _allowEventEnd: boolean = false;
    @Input()
    set allowEventEnd(v: boolean) {
        this._allowEventEnd = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).allowEventEnd = v;
        }

    }

    private _allowEventCheckin: boolean = false;
    @Input()
    set allowEventCheckin(v: boolean) {
        this._allowEventCheckin = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).allowEventCheckin = v;
        }
    }

    private _allowEventCheckout: boolean = false;
    @Input()
    set allowEventCheckout(v: boolean) {
        this._allowEventCheckout = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).allowEventCheckout = v;
        }

    }

    private _isOnline: boolean = false;
    @Input()
    set isOnline(v: boolean) {
        this._isOnline = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).isOnline = v;
        }

    }

    private _isUpdating: boolean = true;
    @Input()
    set updating(v: boolean) {
        this._isUpdating = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).isUpdating = v;
        }

    }

    private _date: Date;
    @Input()
    set date(v: Date) {
        this._date = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).date = v;
        }

    }

    private _license: LicenseInfo;
    @Input()
    set license(v: LicenseInfo) {
        this._license = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).license = v;
        }

    }

    private _config: IAConfig;
    @Input('config')
    set config(v: IAConfig) {
        this._config = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).config = v;
        }

    }

    private _space: SpaceInfo;
    @Input()
    set space(v: SpaceInfo) {
        this._space = v;
        if (this._componentRef) {
            (<ICalendarContent>this._componentRef.instance).space = v;
        }
    }

    @ViewChild(CalendarContentDirective, { static: true }) adHost!: CalendarContentDirective;
    
    @Output() onAction = new EventEmitter<{ action: CalendarAction, data?: any }>();

    constructor(private calendarContentSvc: CalendarContentService) { }

    ngOnInit(): void {
        this.loadComponent(environment.template);
    }

    loadComponent(template: string) {
        const item: CalendarContentItem = this.calendarContentSvc.getContentItem(template);

        const viewContainerRef = this.adHost.viewContainerRef;
        viewContainerRef.clear();

        this._componentRef = viewContainerRef.createComponent<ICalendarContent>(item.component);
        (<ICalendarContent>this._componentRef.instance).account = this._account;
        (<ICalendarContent>this._componentRef.instance).logo = this._logoUrl;
        (<ICalendarContent>this._componentRef.instance).events = this._events;
        (<ICalendarContent>this._componentRef.instance).currentEvent = this._currentEvent;
        (<ICalendarContent>this._componentRef.instance).nextEvent = this._nextEvent;
        (<ICalendarContent>this._componentRef.instance).config = this._config;
        (<ICalendarContent>this._componentRef.instance).date = this._date;
        (<ICalendarContent>this._componentRef.instance).allowEventCheckin = this._allowEventCheckin;
        (<ICalendarContent>this._componentRef.instance).allowEventCheckout = this._allowEventCheckout;
        (<ICalendarContent>this._componentRef.instance).allowEventEnd = this._allowEventEnd;
        (<ICalendarContent>this._componentRef.instance).license = this._license;
        (<ICalendarContent>this._componentRef.instance).isOnline = this._isOnline;
        (<ICalendarContent>this._componentRef.instance).isUpdating = this._isUpdating;
        (<ICalendarContent>this._componentRef.instance).space = this._space;
        (<ICalendarContent>this._componentRef.instance).onActionComplete = this.onActionComplete.bind(this);
    }

    onActionComplete(action: CalendarAction, data?: any): void {
        this.onAction.emit({ action: action, data: data });
    }
}
import { IAConfig } from "src/app/app-config.model";
import { IAEventInfo, IAUserInfo, ICalendarWorkplace, SpaceInfo } from "./calendar.data";
import { AccountInfo } from "@azure/msal-browser";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { CalendarHelper } from "./calendar.helper";
import { of } from "rxjs";
import { Helper } from "src/app/lib/helper";

export class CalendarWorkplaceServiceNow implements ICalendarWorkplace {
    tag: string = '[serviceNow]';
    token: string;

    private _account: AccountInfo;
    get account(): AccountInfo {
        return this._account;
    }
    private _resourceAccount: { username: string, name: string, id?: string };
    get resourceAccount(): { username: string, name: string, id?: string } {
        return this._resourceAccount || { username: this._account.space?.spaceName, name: this._account.space?.spaceName };
    }
    set resourceAccount(account: { username: string, name: string, id?: string }) {
        this._resourceAccount = account;
    }

    constructor(private http: HttpClient, private options: { account: AccountInfo }) {
        this._account = options.account;
    }

    async getUserProfile(account: string): Promise<{ isFault: boolean, data?: IAUserInfo, error?: string | number, errorMessage?: string }> {
        throw new Error("Method not implemented.");
    }

    getRooms(): Promise<{ isFault: boolean, data?: any[], error?: string | number, errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    getCalendarByDate(d: Date, force: boolean = false): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string; }> {
        return this.http.get(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/calendar/${this._account.other.deviceID}?force=${force}`, this.getPluginRequestHeaders()).pipe(
            map((res: { error: string | number, data?: { calendar: any[], space: any, lastConfigUpdateTime: number }, errorMessage?: string}) => {
                if (res.error !== 0) {
                    return {
                        isFault: true,
                        error: res.error,
                        errorMessage: res.errorMessage
                    };
                }

                return {
                    isFault: false,
                    data: {
                        calendar: res.data?.calendar?.map(d => new IAEventInfo(d)),
                        space: new SpaceInfo(res.data?.space),
                        lastConfigUpdateTime: res.data?.lastConfigUpdateTime
                    }
                }
            })
        ).toPromise();
    }

    addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        console.log(`[${this.tag} create adhoc event between`, startDate, endDate);
        
        return this.http.post(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/calendar/${this._account.other.deviceID}/add`, {
            startDate: this.getUTCDateString(startDate),
            endDate: this.getUTCDateString(endDate),
            subject: subject,
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: string | number, errorMessage?: string, data?: any}) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        console.log(`${this.tag} extend event with `, arguments);

        let endDate: Date = event.endDate;
        endDate.setMinutes(endDate.getMinutes() + durationInMinute);

        return this.http.patch(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/calendar/${this._account.other.deviceID}/update/${event.id}`, {
            startDate: this.getUTCDateString(event.startDate),
            endDate: this.getUTCDateString(endDate)
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: string | number, errorMessage?: string, data?: any}) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    stopEvent(event: IAEventInfo, stopDateTime?: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string; }> {
        console.log(`${this.tag} stop event with`, arguments);

        const endDate: Date = stopDateTime;
        endDate.setSeconds(0, 0);

        return this.http.patch(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/calendar/${this._account.other.deviceID}/update/${event.id}`, {
            startDate: this.getUTCDateString(event.startDate),
            endDate: this.getUTCDateString(endDate)
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any}) => {
                return {
                    isFault: res.error !== 0,
                    data: endDate,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        console.log(`${this.tag} cancel event with`, arguments);

        return this.http.patch(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/calendar/${this._account.other.deviceID}/cancel/${event.id}`, {
            comment: comment
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any}) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        console.log(`${this.tag} check-in event with`, event);

        let startDateStr: string = this.getUTCDateString(event.startDate);
        if (Date.now() < event.startDate.getTime()) {
            startDateStr = this.getUTCDateString(new Date());
        }

        return this.http.patch(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/calendar/${this._account.other.deviceID}/checkin/${event.id}`, {
            startDate: startDateStr,
            endDate: this.getUTCDateString(event.endDate)
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any}) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        console.log(`${this.tag} check-in event with`, event);

        return this.http.patch(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/calendar/${this._account.other.deviceID}/checkout/${event.id}`, {}, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any}) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    getConfig(): Promise<{ isFault: boolean, data?: IAConfig, error?: string | number, errorMessage?: string; }> {
        return this.http.get(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/config/device/${this.account.other.deviceID}`, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, data?: any }) => {
                const config: IAConfig = new IAConfig(res.data.calendar);
                return { isFault: false, data: config };
            }),
            catchError((err) => {
                console.log('Parse config failed. ex: ', err);
                return of({ isFault: true })
            })
        ).toPromise();
    }

    getStreamFile(relPath: string): Promise<{ isFault: boolean, data?: { content: Blob; mimeType?: string; }, error?: string | number, errorMessage?: string; }> {
        return this.http.get(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/config/device/${this.account.other.deviceID}/image/${this.account.environment}/${relPath}`, this.getPluginRequestHeaders({ responseType: 'blob' })).pipe(
            map((res: Blob) => {
                return { isFault: false, data: { content: res, mimeType: res.type}};
            })
        ).toPromise();
    }

    isUnpaired(errorCode: string | number): boolean {
        return errorCode === 'E_NOT_PAIR';
    }

    getQRCodeLink(options?: { title: string, lang: string }): Promise<string> {
        return Promise.resolve(this._account.space?.spaceQRCodeLink);
    }

    private getUTCDateString(d: Date): string {
        return `${d.getUTCFullYear()}-${CalendarHelper.padTime(d.getUTCMonth() + 1)}-${CalendarHelper.padTime(d.getUTCDate())} ${CalendarHelper.padTime(d.getUTCHours())}:${CalendarHelper.padTime(d.getUTCMinutes())}:00`;
    }

    private getPluginRequestHeaders(options?: any): { headers: HttpHeaders, responseType?: any } {
        options = options || {};
        options.responseType = options.responseType || 'json';

        let headers: HttpHeaders = new HttpHeaders({});
        headers = headers.append('IAdea-Source-App', 'Booking-for-Workplace');
        if (this.token) {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }

        return { headers: headers, responseType: options.responseType };
    }
}
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="addEventModalLabel">
                {{ title | translate }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h3 *ngIf="!data.isOnline" class="text-center mb-2">
                {{ 'lang.clause.netError' | translate }}
            </h3>
            <h5 class="text-center mt-2">
                {{ 'lang.clause.scanQRCode' | translate }}
            </h5>
            <div class="text-center">
                <qrcode [qrdata]="_data" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { faBell, faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ICalendarAlert } from '../lib/calendar.data';
import { ISlideFuncComponent, SlideAction } from './slide-func.data';

@Component({
    templateUrl: './alert-slide.component.html',
    styleUrls: ['./slide.style.css', './alert-slide.component.css']
})
export class AlertSlideComponent implements ISlideFuncComponent<{ alerts: ICalendarAlert[] }, { to?: SlideAction }> , OnInit {
    onApprove: (action: SlideAction, data?: { to?: SlideAction }) => void;
    onReject: (action: SlideAction, data?: { to?: SlideAction }) => void;

    title: string;
    action: SlideAction;
    data?: { alerts: ICalendarAlert[] };

    readonly ICON_BACK = faChevronLeft;
    readonly ICON_BELL = faBell;
    readonly ICON_TRASH = faTrash;
    _alertList: ICalendarAlert[] = [];

    ngOnInit(): void {
        this._alertList = this.data?.alerts;
    }

    goAdvanceHome(): void {
        this.onApprove(this.action, { to: SlideAction.Advance });
    }

    removeAlert(alert: ICalendarAlert): void {
        this._alertList.splice(this._alertList.indexOf(alert), 1);
    }

    closeAlertView(): void {
        this.onApprove(this.action);
    }
}
import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/lib/helper';
import { CalendarAction } from '../lib/calendar.data';
import { IDlgFuncComponent } from './dlg-func.data';

@Component({
    templateUrl: './warning-dlg.component.html'
})
export class WarningDlgComponent implements IDlgFuncComponent<{ msg: string, msgParams: any[] }, void>, OnInit {
    title: string;
    action: CalendarAction;
    data?: { msg: string, msgParams: any[] };
    onApprove: (action: CalendarAction, data?: void) => void;
    onReject: (action: CalendarAction, data?: void) => void;

    _msgParam: { [paramIndex: string]: any} = {};

    ngOnInit(): void {
        this._msgParam = Helper.arrayToObjectWithNumberIndex(this.data?.msgParams);
    }

    confirm(): void {
        this.onApprove(this.action);
    }

    cancel(): void {
        this.onReject(this.action);
    }
}
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="addEventModalLabel">
                {{ title | translate }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancel()">
            </button>
        </div>
        <div class="modal-body">
            <div class="mb-2">
                <label for="evSubject" class="d-flex align-items-center">
                    {{ 'lang.word.eventName' | translate }}
                    <span class="ms-auto me-2">{{ 'lang.word.allDay' | translate }}</span>
                    <label class="switch">
                        <input #addEventAllDaySwitch id="add-event-allDay-switch" type="checkbox"
                            [checked]="_bookData.isAllDay" (change)="_bookData.isAllDay = $event.target.checked">
                        <span for="add-event-allDay-switch" class="slider round"></span>
                    </label>
                </label>
                <input id="evSubject" type="text" class="form-control"
                    placeholder="{{ DEFAULT_EVENT_SUBJECT | translate | titlecase }}" [(ngModel)]="_bookData.subject" autofocus>
            </div>
            <div [class.d-none]="addEventAllDaySwitch.checked">
                <label for="evStartTime">
                    {{ 'lang.word.startTime' | translate }}
                </label>
                <div id="evStartTime" class="time-option-block">
                    <div *ngFor="let startTimeOption of _startTimeRecommandOptions" class="time-option">
                        <input [id]="startTimeOption.id" type="radio" name="startTimeOption"
                            [class.checked]="_startTimeOption?.id === startTimeOption.id"
                            (change)="changeStartTime(startTimeOption)">
                        <label [for]="startTimeOption.id">
                            {{ startTimeOption.date | pipeTime }}
                        </label>
                    </div>
                </div>
            </div>
            <div [class.d-none]="addEventAllDaySwitch.checked">
                <label for="evDuration">
                    {{ 'lang.word.duration' | translate }} ({{ 'lang.word.minute' | translate }})
                </label>
                <div id="evDuration" *ngIf="_durationRecommandOptions.length > 0; else tempNoUsefulDurations"
                    class="time-option-block">
                    <div *ngFor="let duration of _durationRecommandOptions" class="time-option">
                        <input [id]="'dur-' + duration" type="radio" name="durationOption"
                            [(ngModel)]="_bookData.durationInMinute"
                            [class.checked]="_bookData.durationInMinute === duration"
                            (change)="changeDuration(duration)">
                        <label [for]="'dur-' + duration">
                            {{ duration }}
                        </label>
                    </div>
                </div>
                <ng-template #tempNoUsefulDurations>
                    <div class="text-danger">{{ 'lang.clause.insufficientTime' | translate }}</div>
                </ng-template>
            </div>
            <div>
                <label for="evTime">{{ 'lang.word.meetingTime' | translate }}</label>
                <div id="evTime">
                    <span *ngIf="!addEventAllDaySwitch.checked; else templateAddEventAllDayTimePeriod">
                        {{ _bookData.startDate | pipeTime}} - {{ _bookData.endDate | pipeTime}}
                    </span>
                    <ng-template #templateAddEventAllDayTimePeriod>
                        {{ 'lang.word.allDay' | translate }}
                    </ng-template>
                </div>
            </div>
            <div *ngIf="_errorMsg" class="mt-2 text-danger">
                {{ _errorMsg }}
            </div>
        </div>
        <div class="modal-footer">
            <span class="mx-4 text-secondary">{{ _countdownStr }}</span>
            <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
                {{ 'lang.word.cancel' | translate }}
            </button>
            <button type="button" class="btn btn-primary ms-2"
                [disabled]="(!_bookData.startDate || !_bookData.durationInMinute) && !addEventAllDaySwitch.checked"
                (click)="reserve()">
                {{ 'lang.word.book' | translate }}
            </button>
            <button #btnClose type="button" class="d-none" data-bs-dismiss="modal"></button>
        </div>
    </div>
</div>
import { Component, OnInit } from "@angular/core";

@Component({
    template: '<div></div>'
})
export class TokenParserComponent implements OnInit {
    ngOnInit(): void {
        console.log('[token-parser] init', new URL(window.location.href), navigator.userAgent);
        const url: URL = new URL(window.location.href);
        const token: string = url.searchParams.get('id_token');
        console.log('[token-parser] post token msg');
        parent.postMessage(token, window.location.href);
    }

}
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="addEventModalLabel">
                {{ title | translate }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancel()">
            </button>
        </div>
        <div class="modal-body">
            <div class="mb-2">
                <label for="evSubject" class="d-flex align-items-center">
                    {{ 'lang.word.eventName' | translate }}
                    <span class="ms-auto me-2">{{ 'lang.word.allDay' | translate }}</span>
                    <label class="switch">
                        <input #addEventAllDaySwitch id="add-event-allDay-switch" type="checkbox"
                            [checked]="_bookData.isAllDay" (change)="_bookData.isAllDay = $event.target.checked">
                        <span for="add-event-allDay-switch" class="slider round"></span>
                    </label>
                </label>
                <input id="evSubject" type="text" class="form-control"
                    placeholder="{{ DEFAULT_EVENT_SUBJECT | translate | titlecase }}" [(ngModel)]="_bookData.subject" autofocus>
            </div>
            <div [class.d-none]="addEventAllDaySwitch.checked">
                <div class="mt-4 d-flex align-items-center">
                    <label for="evDuration1">
                        {{ 'lang.word.duration' | translate }} :
                    </label>
                    <div *ngIf="_durationRecommandOptions.length > 0; else tempNoUsefulDurations">
                        <span  class="dropdown ms-4">
                            <button class="btn btn-success dropdown-toggle" type="button" id="evDuration1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {{ _bookData.durationInMinute || 'Select a duration' }}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="evDuration1">
                                <li *ngFor="let duration of _durationRecommandOptions"
                                    [class.checked]="_bookData.durationInMinute === duration"
                                    (click)="changeDuration(duration)">
                                    {{ duration }}
                                </li>
                            </ul>
                        </span>
                        <span class="ms-2">
                            {{ 'lang.word.minute' | translate }}
                        </span>
                    </div>  
                    <ng-template #tempNoUsefulDurations>
                        <div class="text-danger">{{ 'lang.clause.insufficientTime' | translate }}</div>
                    </ng-template>
                </div>
            </div>
            <div class="mt-4">
                <label for="evTime">{{ 'lang.word.meetingTime' | translate }} :</label>
                <div id="evTime">
                    <span *ngIf="!addEventAllDaySwitch.checked; else templateAddEventAllDayTimePeriod">
                        {{ _bookData.startDate | pipeTime}} - {{ _bookData.endDate | pipeTime}}
                    </span>
                    <ng-template #templateAddEventAllDayTimePeriod>
                        {{ 'lang.word.allDay' | translate }}
                    </ng-template>
                </div>
            </div>
            <div *ngIf="_errorMsg" class="mt-2 text-danger">
                {{ _errorMsg }}
            </div>
        </div>
        <div class="modal-footer">
            <span class="mx-4 text-secondary">{{ _countdownStr }}</span>
            <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
                {{ 'lang.word.cancel' | translate }}
            </button>
            <button type="button" class="btn btn-primary ms-2"
                [disabled]="(!_bookData.startDate || !_bookData.durationInMinute) && !addEventAllDaySwitch.checked"
                (click)="reserve()">
                {{ 'lang.word.book' | translate }}
            </button>
            <button #btnClose type="button" class="d-none" data-bs-dismiss="modal"></button>
        </div>
    </div>
</div>

import { HttpClient } from "@angular/common/http";
import { APIGetLicenseManager } from "./license.post";
import { APIGetLicenseByDeviceSignatureManager } from "./licenseByDeviceSig.post";


export class APILicenseWrapper {
    GetLicense: APIGetLicenseManager;
    GetLicenseByDeviceSignature: APIGetLicenseByDeviceSignatureManager;

    constructor(private http: HttpClient) {
        this.GetLicense = new APIGetLicenseManager(http);
        this.GetLicenseByDeviceSignature = new APIGetLicenseByDeviceSignatureManager(http);
    }
}

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAConfig, IALocalConfig } from '../app-config.model';
import { AppConfigService } from '../app-config.service';
import { ErrorType } from '../calendar/lib/calendar.data';
import { Helper } from './helper';
import { CalendarService } from '../calendar/lib/calendar.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CacheService {
    private readonly CACHE_KEY_BG_NAME: string = 'IA-Config-bg';
    private readonly CACHE_KEY_LOGO_NAME: string = 'IA-Config-logo';
    private readonly CACHE_KEY_LAST_ACCOUNT: string = 'IA-Config-LastAccount';
    private readonly CACHE_KEY_LAST_RESOURCE_ACCOUNT: string = 'IA-Config-LastResAcc';
    private readonly CACHE_KEY_WEB_CONFIG: string = 'IA-Config';
    private readonly CACHE_KEY_LOCAL_CONFIG: string = 'IA-Config-Local';
    private readonly CACHE_KEY_CONFIG_USE_LOCAL: string = 'IA-Config-UseLocal';
    private readonly CACHE_KEY_LAST_LAUNCHTIME: string = 'IA-Config-LastLaunchTime';
    private readonly MB_TO_KB: number = Math.pow(2, 20);

    private readonly INDEXED_DB_NAME: string = 'BookingForOutlookDB';
    private readonly INDEXED_DB_STORE: string = 'CacheStore';

    private _db: IDBDatabase;

    private _config: IAConfig;
    private _hasWebConfig: boolean = false;
    private _bgUrl: string = environment.resource.bg;
    private _logoUrl: string = environment.resource.logo;
    private _lastAccount: string;
    private _lastLaunchTime: number;
    private _forceUpdateMinute: number;
    private _lastResourceAccount: string;

    private _loadingConfig: boolean = false;
    private _loadingLogo: boolean = false;
    private _loadingBg: boolean = false;

    private _localConfig: IALocalConfig;
    get localConfig(): IALocalConfig {
        return this._localConfig;
    }
    private _useLocalConfig: boolean = false;
    get useLocalConfig(): boolean {
        return this._useLocalConfig;
    }

    private _dateFormatter: Intl.DateTimeFormat;
    get dateFormatter(): Intl.DateTimeFormat {
        return this._dateFormatter;
    }
    private _timeFormatter: Intl.DateTimeFormat;
    get timeFormatter(): Intl.DateTimeFormat {
        return this._timeFormatter;
    }

    get resourceAccount(): string {
        return this._lastResourceAccount;
    }

    constructor(private calendarSvc: CalendarService) {
        from(this.init()).subscribe();
    }

    private async init(): Promise<void> {
        if (!Helper.isTopWindow) {
            return;
        }

        await this.openCache();
        await this.resetConfig();
        let data: { [key: string]: any } = await this.getCache([this.CACHE_KEY_LAST_ACCOUNT, this.CACHE_KEY_BG_NAME, this.CACHE_KEY_LOGO_NAME, this.CACHE_KEY_LAST_RESOURCE_ACCOUNT]);
        this._lastAccount = data[this.CACHE_KEY_LAST_ACCOUNT];
        this._bgUrl = data[this.CACHE_KEY_BG_NAME];
        this._logoUrl = data[this.CACHE_KEY_LOGO_NAME];
        this._lastResourceAccount = data[this.CACHE_KEY_LAST_RESOURCE_ACCOUNT];

        this._lastLaunchTime = new Date().getTime();
        this._forceUpdateMinute = Helper.roundToFix(Math.random() * 30, 0);
        this.updateCache({ key: this.CACHE_KEY_LAST_LAUNCHTIME, value: this._lastLaunchTime });
        this.initDateTimeFormatter();
    }

    private async resetConfig(): Promise<void> {
        // config from OEM config file.
        this._config = AppConfigService.config;

        let data: { [key: string]: any } = await this.getCache([this.CACHE_KEY_WEB_CONFIG, this.CACHE_KEY_LOCAL_CONFIG, this.CACHE_KEY_CONFIG_USE_LOCAL]);
        if (!data) {
            return;
        }

        // merge config with web config
        const cacheWebConfig: IAConfig = data[this.CACHE_KEY_WEB_CONFIG] ? JSON.parse(data[this.CACHE_KEY_WEB_CONFIG]) : null;
        this._config.merge(cacheWebConfig);

        // merge config with local config
        this._localConfig = data[this.CACHE_KEY_LOCAL_CONFIG] ? JSON.parse(data[this.CACHE_KEY_LOCAL_CONFIG]) : null;
        this._useLocalConfig = data[this.CACHE_KEY_CONFIG_USE_LOCAL] === 'true';

        if (this._useLocalConfig) {
            console.log('[cache] merge local config');
            this._config.merge(this._localConfig, true);
        }

        console.log('[cache] merged config = ', this._config);
        return;
    }

    doForceUpdate(d: Date): void {
        if (d.getHours() === 12 && d.getMinutes() === this._forceUpdateMinute) {
            console.log('[cache] prepare force reload');
            const passedMilliSeconds: number = d.getTime() - this._lastLaunchTime;
            if (!this._lastLaunchTime || Math.abs(passedMilliSeconds) > 129600000) {
                document.location.reload();
            }
        }
    }

    async getBg(refresh: boolean = false): Promise<{ isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }> {
        console.log(`[cache] get bg, refresh? = ${refresh} `);

        if (refresh) {
            if (this._loadingBg) {
                return Helper.waitUntil(() => !this._loadingBg).pipe(
                    map(() => ({ isFault: !this._bgUrl, data: this._bgUrl }))
                ).toPromise();
            }

            this._loadingBg = true;
            const resConfig: { config: IAConfig, errorMsg?: string } = await this.getConfig();
            const resImg: { isFault: boolean, b64Data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] } = await this.loadImageStream(resConfig.config.background, this._config.resource.bg.sizeLimit, this._config.resource.bg.supportMimeTypes);
            if (!resImg.isFault && resImg.b64Data) {
                this._bgUrl = resImg.b64Data;
                await this.updateCache({ key: this.CACHE_KEY_BG_NAME, value: this._bgUrl });
            }

            this._loadingBg = false;
            if (resImg.errorType === ErrorType.Size) {
                return { isFault: resImg.isFault, data: this._bgUrl, errorType: resImg.errorType, errorMsg: 'lang.clause.bgSizeExceed', errorMsgParams: [this._config.resource.bg.sizeLimit] };
            }

            return { isFault: resImg.isFault, data: this._bgUrl, errorType: resImg.errorType, errorMsg: resImg.errorMsg, errorMsgParams: resImg.errorMsgParams };
        }

        return { isFault: false, data: this._bgUrl };
    }

    async getLogo(refresh: boolean = false): Promise<{ isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }> {
        console.log(`[cache] get logo, refresh? = ${refresh} `);

        if (refresh) {
            if (this._loadingLogo) {
                return Helper.waitUntil(() => !this._loadingLogo).pipe(
                    map(() => ({ isFault: !this._bgUrl, data: this._logoUrl }))
                ).toPromise();
            }

            this._loadingLogo = true;
            const resConfig: { config: IAConfig, errorMsg?: string } = await this.getConfig();
            const resImg: { isFault: boolean, b64Data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] } = await this.loadImageStream(resConfig.config.logo, this._config.resource.logo.sizeLimit, this._config.resource.logo.supportMimeTypes);
            if (!resImg.isFault && resImg.b64Data) {
                this._logoUrl = resImg.b64Data;
                await this.updateCache({ key: this.CACHE_KEY_LOGO_NAME, value: this._logoUrl });
            }

            this._loadingLogo = false;

            if (resImg.errorType === ErrorType.Size) {
                return { isFault: resImg.isFault, data: this._bgUrl, errorType: resImg.errorType, errorMsg: 'lang.clause.logoSizeExceed', errorMsgParams: [this._config.resource.bg.sizeLimit] };
            }

            return { isFault: resImg.isFault, data: this._logoUrl, errorType: resImg.errorType, errorMsg: resImg.errorMsg, errorMsgParams: resImg.errorMsgParams };
        }

        return { isFault: false, data: this._logoUrl };
    }

    async saveLocalConfig(config: IAConfig, useLocalConfig: boolean): Promise<void> {
        await this.updateCache([{ key: this.CACHE_KEY_LOCAL_CONFIG, value: JSON.stringify(config) }, { key: this.CACHE_KEY_CONFIG_USE_LOCAL, value: useLocalConfig }]);

        this.resetConfig();
        this.initDateTimeFormatter();
    }

    async saveLoginAccount(account: string): Promise<void> {
        if (this._lastAccount !== account) {
            await this.updateCache({ key: this.CACHE_KEY_LAST_ACCOUNT, value: account });
            await this.removeCache([this.CACHE_KEY_BG_NAME, this.CACHE_KEY_LOGO_NAME, this.CACHE_KEY_WEB_CONFIG, this.CACHE_KEY_CONFIG_USE_LOCAL, this.CACHE_KEY_LOCAL_CONFIG]);

            this._bgUrl = null;
            this._logoUrl = null;
            await this.resetConfig();
        }
    }

    async saveResourceAccount(resourceAccount: string): Promise<void> {
        if (this._lastResourceAccount !== resourceAccount) {
            await this.updateCache({ key: this.CACHE_KEY_LAST_RESOURCE_ACCOUNT, value: resourceAccount });
        }
    }

    async getConfig(forceRefresh: boolean = false): Promise<{ config: IAConfig, errorMsg?: string }> {
        console.log('[cache] get config, refresh = ', forceRefresh);

        if (this._loadingConfig) {
            console.log('[cache] loading config...');
            return Helper.waitUntil(() => !this._loadingConfig).pipe(
                map(() => ({ config: this._config }))
            ).toPromise();
        }

        if (forceRefresh) {
            this._loadingConfig = true;
            const res: { isFault: boolean, data?: IAConfig, errorMessage?: string } = await this.calendarSvc.getConfig();
            console.log('[cache] global config result = ', res);

            if (!res.isFault) {
                this._hasWebConfig = true;
                await this.updateCache({ key: this.CACHE_KEY_WEB_CONFIG, value: JSON.stringify(res.data) });
            }
            else {
                this._hasWebConfig = false;
            }

            await this.resetConfig();
            this.initDateTimeFormatter();

            this._loadingConfig = false;

            return { config: this._config, errorMsg: res.errorMessage };
        }

        return { config: this._config };
    }

    private initDateTimeFormatter(): void {
        this._dateFormatter = new Intl.DateTimeFormat(this._config.locale, {
            year: this._config.dateTimeOption.year,
            month: this._config.dateTimeOption.month,
            day: this._config.dateTimeOption.day,
            weekday: this._config.dateTimeOption.weekday
        });

        //special handling for hourCycle under es2015?
        //if hour12 is set (no matter true or false), hourCycle is useless
        //so now only set hour12 if it is true
        //hourCycle == h24 will represents the time '00:12:00' as '24:12:00', and it seems the default setting of chrome, so set h23 as default?
        const timeOptions: Intl.DateTimeFormatOptions & { hourCycle?: string } = {
            hour: this._config.dateTimeOption.hour,
            minute: this._config.dateTimeOption.minute,
            hourCycle: 'h23'
        };
        if (this._config.dateTimeOption.hour12) {
            timeOptions.hour12 = this._config.dateTimeOption.hour12;
        }

        this._timeFormatter = new Intl.DateTimeFormat(this._config.locale, timeOptions);
    }

    private async loadImageStream(relPath: string, fileSizeLimit: number, supportMimeTypes: string[] = []): Promise<{ isFault: boolean, b64Data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }> {
        if (!this._hasWebConfig) {
            return { isFault: false };
        }

        if (!relPath) {
            //do not assign resource relative path. use default.
            return { isFault: false };
        }

        const resRet: { isFault: boolean, data?: { content: Blob, mimeType?: string }, errorMessage?: string } = await this.calendarSvc.getStreamFile(relPath);
        if (resRet.isFault) {
            return { isFault: true, errorType: ErrorType.API, errorMsg: 'lang.clause.apiError', errorMsgParams: ['(' + relPath + ') ' + resRet.errorMessage] };
        }

        if (resRet.data.content.size > (fileSizeLimit * this.MB_TO_KB)) {
            //decide the error msg on parent function.
            return { isFault: true, errorType: ErrorType.Size };
        }

        if (supportMimeTypes && supportMimeTypes.length > 0) {
            const mime: string = resRet.data.mimeType.replace(/image\//, '');
            if (!supportMimeTypes.find(supportMime => supportMime === mime)) {
                return { isFault: true, errorType: ErrorType.Format, errorMsg: 'lang.clause.imgFormatError', errorMsgParams: [relPath] };
            }
        }

        try {
            const b64str: string = await Helper.blobToBase64(resRet.data.content).toPromise();
            return { isFault: false, b64Data: b64str };
        }
        catch (error) {
            return { isFault: true, errorType: ErrorType.Internal, errorMsg: 'lang.clause.internalError', errorMsgParams: [error.toString()] };
        }
    }

    private openCache(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (window.indexedDB) {
                console.log('[cache] open indexed DB');
                let req: IDBOpenDBRequest = indexedDB.open(this.INDEXED_DB_NAME, 1);
                req.onupgradeneeded = (ev: IDBVersionChangeEvent) => {
                    this._db = req.result;
                    this._db.createObjectStore(this.INDEXED_DB_STORE);
                };
                req.onsuccess = (ev: Event) => {
                    console.log('[cache] open indexed DB success', ev);
                    this._db = req.result;
                    resolve();
                };
                req.onerror = (ev: any) => {
                    console.error('[cache] open indexed DB failed', ev);
                    reject(ev.target.errorCode);
                };

                return;
            }

            resolve();
        });
    }

    private getCache(cacheNames: string | string[]): Promise<{ [key: string]: any }> {
        if (!Array.isArray(cacheNames)) {
            cacheNames = [cacheNames];
        }

        let inputs: string[] = cacheNames;
        return new Promise<{ [key: string]: any }>((resolve, reject) => {
            if (window.indexedDB && this._db) {
                let tx = this._db.transaction(this.INDEXED_DB_STORE, 'readonly');
                let store = tx.objectStore(this.INDEXED_DB_STORE);

                const cacheKeySet = new Set(inputs);
                const data: { [key: string]: any } = {};
                let req = store.openCursor();

                req.onsuccess = (ev: any) => {
                    let cursor: IDBCursorWithValue = ev.target.result;
                    if (cursor) {
                        const recordKey: string = cursor.key as string;
                        if (cacheKeySet.has(recordKey)) {
                            data[recordKey] = cursor.value;
                        }

                        cursor.continue();
                    }
                    else {
                        resolve(data);
                    }
                };

                req.onerror = (ev: any) => {
                    console.error('[cache] getCache by indexedDB failed. Error = ', ev);
                    reject(ev.target.errorCode);
                };
            }
            else {
                // use localstorage
                let data: { [key: string]: any } = {};
                inputs.forEach(key => {
                    data[key] = localStorage.getItem(key);
                });

                resolve(data);
            }
        });
    }

    private updateCache(items: { key: string, value: any } | { key: string, value: any }[]): Promise<void> {
        if (!Array.isArray(items)) {
            items = [items];
        }

        let inputs: { key: string, value: any }[] = items;
        return new Promise<void>((resolve, reject) => {
            if (window.indexedDB && this._db) {
                let tx = this._db.transaction([this.INDEXED_DB_STORE], 'readwrite');
                let store = tx.objectStore(this.INDEXED_DB_STORE);

                inputs.forEach(item => {
                    let req = store.put(item.value, item.key);
                    req.onsuccess = (ev) => { };
                    req.onerror = (ev) => {
                        console.error(`[cache] update item ${item.key} to ${item.value} failed. Error = `, ev);
                    };
                });

                tx.oncomplete = () => {
                    resolve();
                };
                tx.onerror = (ev: any) => {
                    console.error('[cache] update cache by indexedDB failed. Error = ', ev);
                    reject(ev.target.errorCode);
                };
            }
            else {
                inputs.forEach(item => {
                    try {
                        localStorage.setItem(item.key, item.value);
                    }
                    catch (ex) {
                        console.error(`[cache] update cache ${item.key} by localStorage failed. Ex: `, ex);
                    }
                });

                resolve();
            }
        });
    }

    private removeCache(keys: string[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (window.indexedDB && this._db) {
                let tx = this._db.transaction([this.INDEXED_DB_STORE], 'readwrite');
                let store = tx.objectStore(this.INDEXED_DB_STORE);

                keys.forEach(key => {
                    let req = store.delete(key);
                    req.onsuccess = (ev) => { };
                    req.onerror = (ev) => {
                        console.error(`[cache] delete cache ${key} failed. Error =`, ev);
                    };
                });

                tx.oncomplete = () => {
                    resolve();
                };
                tx.onerror = (ev: any) => {
                    console.error('[cache] delete cache by indexedDB failed. Error = ', ev);
                    reject(ev.target.errorCode);
                };
            }
            else {
                keys.forEach((key) => {
                    try {
                        localStorage.removeItem(key);
                    }
                    catch (ex) {
                        console.error('[cache] delete cache by localStorage failed. Error = ', ex);
                    }
                });

                resolve();
            }
        });
    }
}
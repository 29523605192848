<div class="offcanvas-header">
    <div class="center">
        <h5>
            <fa-icon [icon]="ICON_COG" class="me-2"></fa-icon>
            {{ title | translate }}
        </h5>
    </div>
    <div class="right">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
</div>
<div class="offcanvas-body">
    <div class="advance-item" (click)="inspectNotification()">
        <fa-icon [icon]="ICON_BELL" class="me-2"></fa-icon>
        {{ 'lang.word.notification' | translate }}
        <span class="ms-1">({{ _alertCount }})</span>
        <fa-icon [icon]="ICON_NEXT" class="hover ms-auto"></fa-icon>
    </div>
    <div class="advance-item" [class.disabled]="!_license" (click)="editLocalConfigs()">
        <fa-icon [icon]="ICON_SLIDER" class="me-2"></fa-icon>
        {{ 'lang.word.config' | translate }}
        <fa-icon [icon]="ICON_NEXT" class="hover ms-auto"></fa-icon>
    </div>
    <div *ngIf="_supportRoomSelection" class="advance-item" (click)="selectRoom()">
        <fa-icon [icon]="ICON_BUILDING" class="me-2"></fa-icon>
        {{ 'Select another room' | translate }}
    </div>
    <div>
        <hr class="dropdown-divider">
    </div>
    <div *ngIf="_supportLogout" class="advance-item mt-4" data-bs-dismiss="offcanvas" (click)="logout()">
        <fa-icon [icon]="ICON_LOGOUT" class="me-2"></fa-icon>
        {{ 'lang.word.logout' | translate }}
    </div>
    <div class="mt-4 text-center fst-italic text-muted">
        <small>{{ 'lang.word.version' | translate }} {{ CONFIG_VERSION }}</small>
    </div>
</div>
<div class="offcanvas-header">
    <div class="prev" (click)="goAdvanceHome()">
        <fa-icon [icon]="ICON_BACK" class="me-2"></fa-icon>
    </div>
    <div class="center">
        <h5>
            <fa-icon [icon]="ICON_SLIDER" class="me-2"></fa-icon>
            {{ title | translate }}
        </h5>
    </div>
    <div class="right">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
</div>
<div class="offcanvas-body">
    <div class="mb-2 row align-items-center">
        <label class="form-label col-5">{{ 'lang.word.globalConfig' | translate }}</label>
        <label class="switch ms-2">
            <input #globalSwitch id="config-global-switch" type="checkbox" [checked]="!_useLocalConfig.after" (change)="_useLocalConfig.after = !$event.target.checked">
            <span for="config-global-switch" class="slider round"></span>
        </label>
    </div>
    <div>
        <div class="mb-2 row">
            <label class="form-label col-5">{{ 'lang.word.lang' | translate }}</label>
            <div class="col">
                <select class="form-select form-select-sm" aria-label="Language options" [disabled]="globalSwitch.checked" (change)="_config.after.locale = $event.target.value">
                    <option *ngFor="let lang of LANGUAGE_LIST" [attr.value]="lang.key" [selected]="lang.key === _config.after.locale">
                        {{ lang.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="mb-2">
            <div class="mb-2 row align-items-center">
                <label class="form-label col-5">{{ 'lang.word.lightbar' | translate }}</label>
                <label class="switch ms-2">
                    <input #globalSwitch id="config-lighbar-activate-switch" type="checkbox" [disabled]="globalSwitch.checked" [checked]="_config.after.lightbar.activate" (change)="_config.after.lightbar.activate = $event.target.checked">
                    <span for="config-lighbar-activate-switch" class="slider round"></span>
                </label>
            </div>
            <fieldset>
                <legend>{{ 'lang.word.available' | translate }}</legend>
                <div class="mb-2 d-flex align-items-center row">
                    <label class="form-label col-6">{{ 'lang.word.mode' | translate }}</label>
                    <div class="col">
                        <label class="switch">
                            <input #lightbarFreeSwitch id="config-lightbar-free-mode-switch" type="checkbox" [disabled]="globalSwitch.checked || !_config.after.lightbar.activate" [checked]="_config.after.lightbar.available.mode === 'on'" (change)="_config.after.lightbar.available.mode = $event.target.checked ? 'on' : 'off'">
                            <span for="config-lightbar-free-mode-switch" class="slider"></span>
                        </label>
                    </div>
                </div>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-lightbar-free-color'" [title]="'lang.word.color'" [disabled]="globalSwitch.checked || !_config.after.lightbar.activate || !lightbarFreeSwitch.checked" [color]="_config.after.lightbar.available.color" (onColorChanged)="_config.after.lightbar.available.color = $event"></ca-color-picker>
                </div>
            </fieldset>
            <fieldset>
                <legend>{{ 'lang.word.inuse' | translate }}</legend>
                <div class="mb-2 d-flex align-items-center row">
                    <label class="form-label col-6">{{ 'lang.word.mode' | translate }}</label>
                    <div class="col">
                        <label class="switch">
                            <input #lightbarBusySwitch id="config-lightbar-busy-mode-switch" type="checkbox" [disabled]="globalSwitch.checked || !_config.after.lightbar.activate" [checked]="_config.after.lightbar.busy.mode === 'on'" (change)="_config.after.lightbar.busy.mode = $event.target.checked ? 'on' : 'off'">
                            <span for="config-lightbar-busy-mode-switch" class="slider"></span>
                        </label>
                    </div>
                </div>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-lightbar-busy-color'" [title]="'lang.word.color'" [disabled]="globalSwitch.checked || !_config.after.lightbar.activate || !lightbarBusySwitch.checked" [color]="_config.after.lightbar.busy.color" (onColorChanged)="_config.after.lightbar.busy.color = $event"></ca-color-picker>
                </div>
            </fieldset>
        </div>
        <div class="mb-2">
            <label class="form-label">{{ 'lang.word.color' | translate }}</label>
            <fieldset>
                <legend>{{ 'lang.word.theme' | translate }}</legend>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-color-main-foreground'" [title]="'lang.word.foreground'" [disabled]="globalSwitch.checked" [color]="_config.after.theme.foreground" (onColorChanged)="_config.after.theme.foreground = $event"></ca-color-picker>
                </div>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-color-main-free'" [title]="'lang.word.available'" [disabled]="globalSwitch.checked" [color]="_config.after.theme.freeColor" (onColorChanged)="_config.after.theme.freeColor = $event"></ca-color-picker>
                </div>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-color-main-busy'" [title]="'lang.word.inuse'" [disabled]="globalSwitch.checked" [color]="_config.after.theme.busyColor" (onColorChanged)="_config.after.theme.busyColor = $event"></ca-color-picker>
                </div>
            </fieldset>
            <fieldset>
                <legend>{{ 'lang.word.timeline' | translate }}</legend>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-color-timeline-bg'" [title]="'lang.word.bg'" [disabled]="globalSwitch.checked" [color]="_config.after.theme.timeline.bgColor" (onColorChanged)="_config.after.theme.timeline.bgColor = $event"></ca-color-picker>
                </div>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-color-timeline-future'" [title]="'lang.word.futureEvent'" [disabled]="globalSwitch.checked" [color]="_config.after.theme.timeline.futureEventTimeBlockColor" (onColorChanged)="_config.after.theme.timeline.futureEventTimeBlockColor = $event"></ca-color-picker>
                </div>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-color-timeline-current'" [title]="'lang.word.currentEvent'" [disabled]="globalSwitch.checked" [color]="_config.after.theme.timeline.currentEventTimeBlockColor" (onColorChanged)="_config.after.theme.timeline.currentEventTimeBlockColor = $event"></ca-color-picker>
                </div>
                <div class="mb-2 row">
                    <ca-color-picker [id]="'config-color-timeline-expired'" [title]="'lang.word.expireEvent'" [disabled]="globalSwitch.checked" [color]="_config.after.theme.timeline.expiredEventTimeBlockColor" (onColorChanged)="_config.after.theme.timeline.expiredEventTimeBlockColor = $event"></ca-color-picker>
                </div>
            </fieldset>
        </div>
    </div>
</div>
<div class="offcanvas-footer">
    <button type="button" class="btn btn-outline-secondary ms-auto" data-bs-dismiss="offcanvas">
        {{ 'lang.word.cancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-secondary ms-2" (click)="reset()">
        {{ 'lang.word.reset' | translate }}
    </button>
    <button type="button" class="btn btn-primary ms-2" data-bs-dismiss="offcanvas" (click)="confirm()">
        {{ 'lang.word.confirm' | translate }}
    </button>
</div>
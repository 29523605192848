import { Component, OnDestroy, OnInit } from "@angular/core";
import { SensorCO2Data } from "./ia-sensor-co2.data";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: 'ia-sensor-co2',
    templateUrl: './ia-sensor-co2.component.html',
    styleUrls: ['./ia-sensor-co2.component.css']
})
export class IASensorCO2Component implements OnInit, OnDestroy {
    private readonly RANDOM_MIN_INDEX: number = 2;
    private readonly RANDOM_MAX_INDEX: number = 12;
    private readonly RANDOM_INTERVAL: number = 60000;

    _datas: SensorCO2Data[] = [];
    _data: SensorCO2Data;
    _indicatorOffsetX: number;

    private readonly _destroying$ = new Subject<void>();

    ngOnInit(): void {
        this._datas = [
            new SensorCO2Data(400, '#1db14d'),
            new SensorCO2Data(500, '#1db14d'),
            new SensorCO2Data(600, '#1db14d'),
            new SensorCO2Data(700, '#91cb38'),
            new SensorCO2Data(800, '#91cb38'),
            new SensorCO2Data(900, '#91cb38'),
            new SensorCO2Data(1000, '#91cb38'),
            new SensorCO2Data(1100, '#fcc40d'),
            new SensorCO2Data(1200, '#fcc40d'),
            new SensorCO2Data(1300, '#fcc40d'),
            new SensorCO2Data(1400, '#fcc40d'),
            new SensorCO2Data(1500, '#fcc40d'),
            new SensorCO2Data(1600, '#f49225'),
            new SensorCO2Data(1700, '#f49225'),
            new SensorCO2Data(1800, '#f49225'),
            new SensorCO2Data(1900, '#f49225'),
            new SensorCO2Data(2000, '#f49225'),
            new SensorCO2Data(2100, '#f21b25'),
        ];

        timer(0, this.RANDOM_INTERVAL).pipe(
            takeUntil(this._destroying$)
        ).subscribe(() => {
            const index: number = Math.floor(Math.random() * (this.RANDOM_MAX_INDEX - this.RANDOM_MIN_INDEX) + this.RANDOM_MIN_INDEX);
            this._data = this._datas[index];
            if (this._data) {
                this._indicatorOffsetX = index * 1.6 - 0.5;
            } 
        });
    }

    ngOnDestroy(): void {
        this._destroying$.next();
        this._destroying$.complete();
    }
}
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ColorPickerMode } from "./ia-color.data";
import { environment } from "src/environments/environment";

@Component({
    selector: 'ca-color-picker',
    templateUrl: './ia-color-picker.component.html',
    styleUrls: ['./ia-color-picker.component.css']
})
export class IAColorPickerComponent {
    _enumColorMode: typeof ColorPickerMode = ColorPickerMode;

    @Input() id: string;
    @Input() title: string;
    @Input() colorMode: ColorPickerMode = environment.system.colorPicker;
    @Input() disabled: boolean = false;

    _colorText: string = '000';
    _color: string = '#000';
    @Input()
    set color(c: string) {
        this._color = c;
        if (this.colorMode === ColorPickerMode.Manual) {
            this._colorText = c.substring(1);
        }
        else {
            this._colorText = c;
        }
    }

    @Output() onColorChanged: EventEmitter<string> = new EventEmitter();

    @ViewChild('colorPicker') colorPickerRef: ElementRef;

    launchColorPicker(): void {
        this.colorPickerRef.nativeElement.click();
    }

    changeColor(color: string): void {
        this.onColorChanged.emit(color.substring(0, 1) === '#' ? color : '#' + color);
    }
}
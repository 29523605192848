import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { AccountInfo, AuthenticationResult } from '@azure/msal-common';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { catchError, concatMap, filter, map } from 'rxjs/operators';
import { OAuthSettings, PERSONAL_TENANTID } from '../auth';
import { environment } from 'src/environments/environment';
import { Helper } from './helper';
import { CalendarScope } from '../calendar/lib/calendar.data';
import { IAdeaService } from './iadea.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _bValidateUser: boolean = false;
    private _scope: CalendarScope = environment.scope;
    public graphClient: Client;
    public activeAccount: AccountInfo;
    private _deviceIdentity: { uuid: string, pairingCode: string };
    get deviceIdentity(): { uuid: string, pairingCode: string } {
        return this._deviceIdentity;
    }

    get scope(): CalendarScope {
        return this._scope;
    }
    get isPersonalAccount(): boolean {
        return this.activeAccount?.tenantId === PERSONAL_TENANTID;
    }

    @Output() loginStatusChanged = new EventEmitter<{ isLogin: boolean, account?: AccountInfo, scope: CalendarScope }>();

    constructor(
        private http: HttpClient,
        private msalService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalBroadcastService: MsalBroadcastService,
        private iadeaSvc: IAdeaService) {

        if (Helper.isTopWindow) {
            this.msalBroadcastService.msalSubject$.pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            ).subscribe((result: EventMessage) => {
                console.log('[authSvc] login success', result);
                if (!this._bValidateUser) {
                    const payload = result.payload as AuthenticationResult;
                    console.log(`[authSvc] set active account to `, payload.account);
                    this.msalService.instance.setActiveAccount(payload.account);
                }
            });

            this.msalBroadcastService.inProgress$.pipe(
                filter((status: InteractionStatus) => !this._bValidateUser && status === InteractionStatus.None),
            ).subscribe(() => {
                const accountList: AccountInfo[] = this.msalService.instance.getAllAccounts();
                console.log('[authSvc] all accounts = ', accountList);
                if (accountList.length > 0) {
                    this.activeAccount = accountList[0];
                    this.initClient();
                    this.loginStatusChanged.emit({ isLogin: true, account: this.activeAccount, scope: this._scope });
                }
                else {
                    this.loginStatusChanged.emit({ isLogin: false, scope: this._scope });
                }
            });
        }
    }

    signin(scope: CalendarScope): Observable<{ isFault: boolean, pairingCode?: string, errorMessage?: string }> {
        this._scope = scope;

        switch (this._scope) {
            case CalendarScope.Azure:
                {
                    return of(this.msalGuardConfig.interactionType).pipe(
                        concatMap((interactionType: InteractionType) => {
                            if (interactionType === InteractionType.Popup) {
                                if (this.msalGuardConfig.authRequest) {
                                    return this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).pipe(
                                        map((response: AuthenticationResult) => {
                                            this.msalService.instance.setActiveAccount(response.account);
                                            return { isFault: false };
                                        })
                                    );
                                } else {
                                    return this.msalService.loginPopup().pipe(
                                        map((response: AuthenticationResult) => {
                                            this.msalService.instance.setActiveAccount(response.account);
                                            return { isFault: false };
                                        })
                                    );
                                }
                            } else {
                                if (this.msalGuardConfig.authRequest) {
                                    this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
                                } else {
                                    this.msalService.loginRedirect();
                                }

                                return of({ isFault: false });
                            }
                        })
                    );
                }
            case CalendarScope.ServiceNow:
                {
                    return of(this.iadeaSvc.isIAdeaDevice(navigator.userAgent)).pipe(
                        concatMap((isIAdeaDevice: boolean) => {
                            if (!isIAdeaDevice) {
                                if (environment.system.lockByIAdea) {
                                    throw 'Not an IAdea device';
                                }
                                
                                return this.iadeaSvc.getDeviceSignature('10.0.10.64').pipe(concatMap(ret => this.iadeaSvc.getDevicePairingCode(ret.data.playerId)));
                            }

                            return this.iadeaSvc.getDeviceSignature('localhost').pipe(concatMap(ret => this.iadeaSvc.getDevicePairingCode(ret.data.playerId)));
                        }),
                        concatMap((devIdentity: { uuid: string, pairingCode: string }) => {
                            console.log('[authSvc] device identity: ', devIdentity);
                            this._deviceIdentity = devIdentity;

                            let header: HttpHeaders = new HttpHeaders();
                            header = header.set('Accept', 'application/json');
                            return this.http.get(`${Helper.getPluginServerBaseUrl()}/workplace/svcnow/config/device/${this._deviceIdentity.uuid}`, { headers: header });
                        }),
                        map((res: { error: string | number, errorMessage: string, data?: any }) => {
                            console.log('[authSvc] query setting from plugin server res: ', res);

                            if (res.error !== 0) {
                                throw res.errorMessage;
                            }

                            this.loginStatusChanged.emit({
                                isLogin: true,
                                account: {
                                    tenantId: res.data.tenantUrl,
                                    username: res.data.space.spaceID,
                                    localAccountId: res.data.username,
                                    homeAccountId: res.data.username,
                                    environment: res.data.registerAccountID,
                                    idToken: res.data.token,
                                    other: { deviceID: this._deviceIdentity.uuid },
                                    space: res.data.space
                                },
                                scope: this._scope
                            });

                            return { isFault: false };
                        }),
                        catchError(error => {
                            console.log('[authSvc] signin error: ', error);
                            return of({ isFault: true, error: error instanceof HttpErrorResponse ? error.message : error })
                        })
                    );
                }
        }
    }

    signout() {
        this.activeAccount = null;
        switch (this._scope) {
            case CalendarScope.Azure:
                {
                    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
                        this.msalService.logoutPopup({
                            postLogoutRedirectUri: environment.auth.redirectUri,
                            mainWindowRedirectUri: "/"
                        });
                    } else {
                        this.msalService.logoutRedirect({
                            postLogoutRedirectUri: environment.auth.redirectUri
                        });
                    }
                }
                break;
            case CalendarScope.ServiceNow:
                {
                    this.loginStatusChanged.emit({ isLogin: false, scope: this._scope });
                }
                break;
        }
    }

    private initClient(): void {
        try {
            // Create an authentication provider for the current user
            const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
                this.msalService.instance as PublicClientApplication,
                {
                    account: this.msalService.instance.getActiveAccount()!,
                    scopes: OAuthSettings.scopes,
                    interactionType: InteractionType.Redirect
                }
            );

            // Initialize the Graph client
            this.graphClient = Client.initWithMiddleware({
                authProvider: authProvider
            });

            console.log('[authSvc] graph client: ', this.graphClient);
        }
        catch (ex) {
            console.error('[authSvc] init client exception: ', ex);
        }
    }
}
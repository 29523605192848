import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { CalendarScope, IAEventInfo, IAUserInfo, ICalendarWorkplace, SpaceInfo } from "./calendar.data";
import { IAConfig } from "src/app/app-config.model";
import { CalendarWorkplaceMockup } from "./calendar.workplace.Mockup";
import { CalendarWorkplaceAzure } from "./calendar.workplace.Azure";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/lib/auth.service";
import { AccountInfo } from "@azure/msal-browser";
import { CalendarWorkplaceServiceNow } from "./calendar.workplace.ServiceNow";

@Injectable({
    providedIn: 'root'
})
export class CalendarService implements ICalendarWorkplace {
    private _workplace: ICalendarWorkplace;
    tag: string = '[calendarSvc]';
    get token(): string {
        return this._workplace?.token;
    }

    private _scope: CalendarScope = CalendarScope.Unknown;
    get scope(): CalendarScope {
        return this._scope;
    }

    get account(): AccountInfo {
        return this._workplace?.account;
    }

    get resourceAccount(): { username: string, name: string, id?: string } {
        return this._workplace?.resourceAccount;
    }
    set resourceAccount(account: { username: string, name: string, id?: string }) {
        if (!this._workplace) {
            throw 'No valid workplace';
        }
        this._workplace.resourceAccount = account;
    }

    constructor(private http: HttpClient, private location: Location, private authSvc: AuthService) { }

    setScope(scope: CalendarScope, options?: any): void {
        if (this._scope !== scope) {
            console.log(`${this.tag} set scope to ${scope}`);
            this._scope = scope;
            switch (this._scope) {
                case CalendarScope.Mockup:
                    {
                        this._workplace = new CalendarWorkplaceMockup();
                    }
                    break;
                case CalendarScope.Azure:
                    {
                        this._workplace = new CalendarWorkplaceAzure(this.http, this.authSvc, this.location);
                    }
                    break;
                case CalendarScope.ServiceNow:
                    {
                        if (options.account) {
                            this._workplace = new CalendarWorkplaceServiceNow(this.http, options);
                        }
                        else {
                            this._scope = CalendarScope.Unknown;
                        }
                    }
                    break;
            }
        }
    }

    setToken(token?: string): void {
        this._workplace.token = token || '';
    }

    getUserProfile(account: string): Promise<{ isFault: boolean, data?: IAUserInfo, error?: string | number, errorMessage?: string }> {
        return this._workplace.getUserProfile(account);
    }

    getRooms(): Promise<{ isFault: boolean, data?: microsoftgraph.Room[], error?: string | number, errorMessage?: string }> {
        return this._workplace.getRooms();
    }

    getCalendarByDate(d: Date, force: boolean = false): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string; }> {
        return this._workplace.getCalendarByDate(d, force);
    }
    extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return this._workplace.extendEvent(event, durationInMinute);
    }
    addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return this._workplace.addEvent(subject, startDate, endDate, isAllDay);
    }
    stopEvent(event: IAEventInfo, stopDateTime: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string; }> {
        return this._workplace.stopEvent(event, stopDateTime);
    }
    cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return this._workplace.cancelEvent(event, comment);
    }
    declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return this._workplace.declineEvent(event);
    }
    checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return this._workplace.checkInEvent(event);
    }
    checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return this._workplace.checkOutEvent(event);
    }
    getConfig(): Promise<{ isFault: boolean; data?: IAConfig, error?: string | number, errorMessage?: string; }> {
        return this._workplace.getConfig();
    }
    getStreamFile(relativePath: string): Promise<{ isFault: boolean, data?: { content: Blob; mimeType?: string; }, error?: string | number, errorMessage?: string; }> {
        return this._workplace.getStreamFile(relativePath);
    }
    isUnpaired(errorCode: string | number): boolean {
        return this._workplace.isUnpaired(errorCode);
    }
    getQRCodeLink(options?: { title: string, lang: string }): Promise<string> {
        return this._workplace.getQRCodeLink(options);
    }
}
<div class="sensor-wrapper">
    <div *ngIf="_occupancy.hasOccupancySensor">
        <div *ngIf="_occupancy.isOccupied; else templateUnoccupied" class="d-flex align-items-center">
            <fa-icon [icon]="ICON_USER_CHECK" class="me-2"></fa-icon>
            <span *ngIf="_occupancy.count > 0">{{ _occupancy.count }}</span>
        </div>
        <ng-template #templateUnoccupied>
            <fa-icon [icon]="ICON_USER" class="me-2 inactive"></fa-icon>
        </ng-template>
    </div>
</div>

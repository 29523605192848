import { Component, OnInit } from "@angular/core";
import { CalendarContentBaseComponent } from "../calendar-content-base.component";
import { CacheService } from "src/app/lib/cache.service";

@Component({
    templateUrl: './calendar-content-demo1.component.html',
    styleUrls: ['./calendar-content.style.css', './calendar-content-demo1.component.css']
})
export class CalendarContentDemo1Component extends CalendarContentBaseComponent implements OnInit {

    readonly TITLE_BG_COLOR_AVAILABLE: string = '#00b080';
    readonly TITLE_BG_COLOR_RESERVED: string = '#ee8512';
    readonly TITLE_BG_COLOR_INUSE: string = '#c00000';

    _titleBgColor: string = this.TITLE_BG_COLOR_AVAILABLE;
    _subjectLangKey: string;
    _plugHelperMessage: string;
    _plugImgUrl: string;

    constructor(protected cacheSvc: CacheService) {
        super(cacheSvc);

    }

    ngOnInit(): void {
        this.allowEventCheckin = this.allowEventCheckout = true;
    }

    protected updateCurrentEvent(): void {
        this._plugHelperMessage = 'or plug in computer';
        this._plugImgUrl = '/assets/resources/usb_cable.png';

        if (this.currentEvent) {
            if (this.currentEvent.isCheckin) {
                this._subjectLangKey = 'lang.word.inuse';
                this._titleBgColor = this.TITLE_BG_COLOR_INUSE;
                this._plugHelperMessage = 'or remove computer';
            }
            else {
                this._subjectLangKey = 'lang.word.reserved';
                this._titleBgColor = this.TITLE_BG_COLOR_RESERVED;
            }
        }
        else {
            this._subjectLangKey = 'lang.word.available';
            this._titleBgColor = this.TITLE_BG_COLOR_AVAILABLE;
        }
    }
}
export enum SensorCO2Quality {
    Excellent = 'Excellent',
    Good = 'Good',
    Fair = 'Fair',
    Mediocre = 'Mediocre',
    Bad = 'Bad'
}

export class SensorCO2Data {
    static MAX_VALUE: number = 2100;
    static MIN_VALUE: number = 400;
    static VALUE_GAP: number = 100;
    static CO2_QUALITY_MAP: Map<SensorCO2Quality, { range_min: number, range_max: number }> = new Map([
        [SensorCO2Quality.Excellent, { range_min: 0, range_max: 2 }],
        [SensorCO2Quality.Good, { range_min: 3, range_max: 4 }],
        [SensorCO2Quality.Fair, { range_min: 5, range_max: 6}],
        [SensorCO2Quality.Mediocre, { range_min: 7, range_max: 11}],
        [SensorCO2Quality.Bad, { range_min: 12, range_max: 17}]
    ]);

    value: number;
    color: string;
    quality: SensorCO2Quality;

    constructor(value: number, color: string) {
        this.value = value <= SensorCO2Data.MIN_VALUE ? SensorCO2Data.MIN_VALUE : (value >= SensorCO2Data.MAX_VALUE ? SensorCO2Data.MAX_VALUE : value);
        this.color = color;
        this.quality = SensorCO2Data.calculateQuality(this.value);
    }

    static calculateQuality(value: number): SensorCO2Quality {
        for (let range of SensorCO2Data.CO2_QUALITY_MAP.entries()) {
            const v: number = Math.floor((value - SensorCO2Data.MIN_VALUE) / SensorCO2Data.VALUE_GAP);
            if (v >= range[1].range_min && v <= range[1].range_max) {
                return range[0];
            }
        }
    }
}
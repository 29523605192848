import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, concatMap, map } from 'rxjs/operators';
import { APIHardwareWrapper } from './iadea/hardware/hardware.wrapper';
import { IGetLEDLightRxData } from './iadea/hardware/hardware.light.get';
import { ISetLEDLightRxData } from './iadea/hardware/hardware.light.post';
import { APIAuthWrapper } from './iadea/auth/auth.wrapper';
import { IGetTokenRxData } from './iadea/auth/auth.token.post';
import { APILicenseWrapper } from './iadea/license/license.wrapper';
import { IGetLicenseRxData } from './iadea/license/license.post';
import { LicenseInfo } from './iadea/license/license.data';
import { v4 as uuidv4 } from 'uuid';
import { Helper } from './helper';

@Injectable()
export class IAdeaService {
    private Auth: APIAuthWrapper;
    private Hardware: APIHardwareWrapper;
    private License: APILicenseWrapper;

    constructor(private http: HttpClient) {
        this.Hardware = new APIHardwareWrapper(http);
        this.Auth = new APIAuthWrapper(http);
        this.License = new APILicenseWrapper(http);
    }

    isIAdeaDevice(userAgentStr: string): boolean {
        return userAgentStr.match(/.*ADAPI\/([\d\.]+) \(UUID:([\d\w-]+)\).*A-SMIL\//) ? true : false;
    }

    getToken(host: string, password?: string): Observable<{ token?: string, passwordRequired: boolean, retry_timeout: number, error?: any }> {
        return this.Auth.GetToken.send(host, null, null, null, { grant_type: 'password', username: 'admin', password: password || '' }, null).pipe(
            map((res: IGetTokenRxData) => ({
                token: res.access_token,
                passwordRequired: false,
                retry_timeout: 0
            })),
            catchError((res: HttpErrorResponse) => of({
                passwordRequired: res.error && res.error.error === 'invalid_client' ? true : false,
                error: res.error,
                retry_timeout: res.error && res.error.retry_timeout ? res.error.retry_timeout : 0
            }))
        )
    }

    getLEDColor(host: string, token: string = ''): Observable<{ id: number, name: string, color: string, mode?: string }[]> {
        return this.Hardware.GetLight.send(host, token, null, null, null).pipe(
            map((res: IGetLEDLightRxData[]) => {
                if (res) {
                    return res.map(r => {
                        return {
                            id: r.id,
                            name: r.name,
                            color: r.color,
                            mode: r.mode
                        }
                    });
                }

                return [];
            })
        );
    }

    setLEDColor(host: string, token: string, id: number, name: string, color: string, mode: string = 'on', brightness: number, persistent: boolean = false): Observable<{ error?: any, ledColor?: string, id?: number }> {
        //set name = 'frame' will turn on all led bar (ex: frameLeft, frameRight)
        //do not set name but set id will only set one led bar.
        return this.Hardware.SetLight.send(host, token, null, null, { id: id, name: name, brightness: brightness, color: color, mode: mode, persistent: persistent }).pipe(
            map((res: ISetLEDLightRxData[]) => {
                const found = res.find(light => light.id === id);
                if (found) {
                    return {
                        id: id,
                        ledColor: found.color
                    };
                }

                return { error: 'Could not find match LED id = ' + id + ' on response' };
            })
        );
    }

    getDevicePairingCode(uuidIn?: string, forceUpdate?: boolean): Observable<{ uuid: string, pairingCode: string }> {
        // check if localstorage has the old uuid
        let uuid: string = uuidIn;
        if (!uuid) {
            uuid = forceUpdate ? '' : localStorage.getItem('uuid');
        }
        if (!uuid) {
            uuid = uuidv4();
            this.setDeviceUuid(uuid);
        }

        return of({ uuid: uuid, pairingCode: Helper.ConvertUuidToPairingCode(uuid) });
    }

    setDeviceUuid(uuidIn: string): void {
        localStorage.setItem('uuid', uuidIn);
    }

    getDeviceSignature(host?: string): Observable<{ isFault: boolean, data?: any, errorMessage?: string }> {
        host = host || 'localhost';

        return this.getToken(host).pipe(
            concatMap((tokenRes: { token?: string, passwordRequired: boolean, retry_timeout: number, error?: any }) => {
                const playerInfo$ = this.transformReqToFormalResponse(this.Hardware.GetDeviceInfo.send(host, tokenRes.token, null, null));
                const fwInfo$ = this.transformReqToFormalResponse(this.Hardware.GetDeviceFirmwareInfo.send(host, tokenRes.token, null, null));
                const platformInfo$ = this.transformReqToFormalResponse(this.Hardware.GetDevicePlatformInfo.send(host, tokenRes.token, null, null));
                const modelInfo$ = this.transformReqToFormalResponse(this.Hardware.GetDeviceModelInfo.send(host, tokenRes.token, null, null));
                const netInfo$ = this.transformReqToFormalResponse(this.Hardware.GetDeviceNetInfo.send(host, tokenRes.token, null, null));

                return zip(playerInfo$, fwInfo$, platformInfo$, modelInfo$, netInfo$);
            }),
            map(([playerInfo, fwInfo, platformInfo, modelInfo, netInfo]) => {
                let errors: string[] = [];
                let availableNetworkInterfaceList: { name: string, hardwareAddress: string }[] = [];
                let BTMACList: string[], ethMACList: string[] = [], wifiMACList: string[] = [];
                let IMEINumberList = [];

                if (!netInfo.isFault) {
                    availableNetworkInterfaceList = netInfo.data?.filter((networkInterface) => networkInterface.hardwareAddress) || [];
                    BTMACList = availableNetworkInterfaceList
                        .filter((networkInterface) => networkInterface.hardwareAddress.toLowerCase().replace(/:/g, '').match('^2cc548[0-9a-f]{6}$'))
                        .map((networkInterface) => networkInterface.hardwareAddress)
                        .sort((a, b) => { return a.localeCompare(b); }) ?? [];

                    ethMACList = BTMACList;
                    wifiMACList = BTMACList;
                }
                else {
                    errors.push(netInfo.errorMessage);
                }

                if (playerInfo.isFault) {
                    errors.push(playerInfo.errorMessage);
                }
                if (fwInfo.isFault) {
                    errors.push(fwInfo.errorMessage);
                }
                if (platformInfo.isFault) {
                    errors.push(fwInfo.errorMessage);
                }
                if (modelInfo.isFault) {
                    errors.push(modelInfo.errorMessage);
                }

                const devSig = {
                    manufacturer: modelInfo.data?.manufacturer,
                    modelName: modelInfo.data?.modelName,
                    device: modelInfo.data?.device,
                    serialNumber: playerInfo.data?.serialNumber,
                    platform: platformInfo.data?.platform,
                    release: platformInfo.data?.release,
                    arch: platformInfo.data?.arch,
                    playerId: playerInfo.data?.playerId,
                    firmwareVersion: fwInfo.data?.firmwareVersion,
                    softwareVersion: fwInfo.data?.softwareVersion,
                    hardwareAddress: availableNetworkInterfaceList.reduce((dic, cur) => { dic[cur.name] = cur.hardwareAddress; return dic }, {}),
                    imeiNumbers: IMEINumberList,
                    ethernetMacAddresses: ethMACList,
                    wifiMacAddresses: wifiMACList,
                    bluetoothMacAddresses: BTMACList,
                };

                return { isFault: errors.length > 0, data: devSig, errorMessage: errors.join(', ') };
            })
        );
    }

    private transformReqToFormalResponse(ob$: Observable<any>): Observable<{ isFault: boolean, data?: any, errorMessage?: string }> {
        return ob$.pipe(
            map(ret => ({ isFault: false, data: ret, errorMessage: null })),
            catchError((error) => {
                let errorWrapper: any = error.error;
                while (errorWrapper && typeof errorWrapper !== 'string') {
                    errorWrapper = errorWrapper.error;
                }

                return of({ isFault: true, errorMessage: errorWrapper })
            })
        );
    }

    getLicenseByIDToken(host: string, token: string, options?: { headerKeyPairs?: { key: string, value: any }[] }): Observable<{ licenses?: { [category: string]: LicenseInfo; }, scope?: string[], error?: string }> {
        return this.License.GetLicense.send(host, null, null, null, { id_token: token }, { otherHeaderKeyPairs: options?.headerKeyPairs }).pipe(
            map((res: IGetLicenseRxData) => {
                if (res.error) {
                    return { error: res.errorMessage };
                }

                return { licenses: res.data[0].licenses, scope: res.data[0].scope };
            })
        );
    }

    getLicenseByDeviceSignature(host: string, signature: any, queryLicenseCategory: string, options?: { headerKeyPairs?: { key: string, value: any }[] }): Observable<{ licenses?: { [category: string]: LicenseInfo; }, scope?: string[], error?: string }> {
        return this.License.GetLicenseByDeviceSignature.send(host, null, null, null, { deviceSignature: signature, license: queryLicenseCategory }, { otherHeaderKeyPairs: options?.headerKeyPairs }).pipe(
            map((res: IGetLicenseRxData) => {
                if (res.error) {
                    return { error: res.errorMessage };
                }

                return { licenses: res.data[0].licenses, scope: res.data[0].scope };
            })
        );
    }
}
<div class="calendar-header" [style.background]="_titleBgColor">
    <div class="d-flex align-items-center">
        {{ account?.name }}
    </div>
    <div class="time">
        {{ date | pipeTime }}
    </div>
</div>
<div class="calendar-content" [class.overlay]="!isOnline">
    <div class="subject">
        {{ _subjectLangKey | translate | pipeTextOverflow: _textOverflowLength }}
    </div>
    <div class="control-block">
        <div>
            <ng-template [ngIf]="space?.checkinRequired">
                <button *ngIf="allowEventCheckin && !currentEvent.isCheckin" id="btnCheckin" type="button"
                    class="btn btn-outline-dark" data-bs-toggle="collapse" data-bs-target="#collapseExtend"
                    aria-expanded="false" [disabled]="isUpdating || !license" (click)="checkinEvent()">
                    {{ 'lang.word.checkin' | translate }}
                </button>
                <button *ngIf="allowEventCheckout && currentEvent.isCheckin" id="btnCheckout" type="button"
                    class="btn btn-outline-dark" data-bs-toggle="collapse" data-bs-target="#collapseExtend"
                    aria-expanded="false" [disabled]="isUpdating || !license" (click)="checkoutEvent()">
                    {{ 'lang.word.checkout' | translate }}
                </button>
            </ng-template>
            <ng-template [ngIf]="!currentEvent">
                <button *ngIf="CONFIG_ENABLE_ONSITE_BOOK" type="button" class="btn btn-outline-dark"
                    [disabled]="isUpdating || !license" (click)="addEvent()">
                    Book Now
                </button>
            </ng-template>
        </div>
        <div class="desc">
            {{ _plugHelperMessage }}
        </div>
    </div>
    <div class="schematic-diagram" [class.activate]="currentEvent?.isCheckin">
        <div class="arrow"></div>
        <div class="usb" [ngStyle]="{ 'background-image': 'url(' + _plugImgUrl + ')'}"></div>
    </div>
    
</div>
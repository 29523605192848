import { Component, OnInit } from "@angular/core";
import { ISlideFuncComponent, SlideAction } from "./slide-func.data";
import { faBell, faCog, faSignOutAlt, faSlidersH, faBuilding, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CalendarService } from "../lib/calendar.service";
import { CalendarScope } from "../lib/calendar.data";
import { environment } from "src/environments/environment";
import { LicenseInfo } from "src/app/lib/iadea/license/license.data";

@Component({
    templateUrl: './advance-slide.component.html',
    styleUrls: ['./slide.style.css', './advance-slide.component.css']
})
export class AdvanceSlideComponent implements ISlideFuncComponent<{ alertCount: number, license: LicenseInfo }, SlideAction>, OnInit {
    title: string;
    action: SlideAction;
    data?: { alertCount: number, license: LicenseInfo };
    onApprove: (action: SlideAction, data?: SlideAction) => void;
    onReject: (action: SlideAction, data?: SlideAction) => void;

    readonly ICON_COG = faCog;
    readonly ICON_BELL = faBell;
    readonly ICON_SLIDER = faSlidersH;
    readonly ICON_BUILDING = faBuilding;
    readonly ICON_LOGOUT = faSignOutAlt;
    readonly ICON_NEXT = faChevronRight;
    readonly CONFIG_VERSION: string = environment.version;

    _supportLogout: boolean = environment.supportLogout && this.calendarSvc.scope !== CalendarScope.Mockup;
    _supportRoomSelection: boolean;
    _alertCount: number = 0;
    _license: LicenseInfo;

    constructor(private calendarSvc: CalendarService) {}

    ngOnInit(): void {
        this._supportRoomSelection = this.calendarSvc.resourceAccount?.username && this.calendarSvc.account?.username !== this.calendarSvc.resourceAccount?.username;
        this._alertCount = this.data?.alertCount;
        this._license = this.data?.license;
    }

    inspectNotification(): void {
        this.onApprove(this.action, SlideAction.Alert);
    }

    editLocalConfigs(): void {
        this.onApprove(this.action, SlideAction.LocalConfig);
    }

    selectRoom(): void {
        this.onApprove(this.action, SlideAction.SelectRoom);
    }

    logout(): void {
        this.onApprove(this.action, SlideAction.Logout);
    }
}
import { Component, Input } from "@angular/core";
import { faUser, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { IAEventInfo, OccupancyInfo } from "../calendar/lib/calendar.data";

@Component({
    selector: 'ia-sensor-people',
    templateUrl: './ia-sensor-people.component.html',
    styleUrls: ['./ia-sensor-people.component.css']
})
export class IASensorPeopleComponent {
    readonly ICON_USER = faUser;
    readonly ICON_USER_CHECK = faUserCheck;

    @Input('occupancy') _occupancy: OccupancyInfo = new OccupancyInfo();
    @Input('currentEvent') _currentEvent: IAEventInfo;
}
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomPipeModule } from '../pipe/custom-pipe.module';
import { CalendarComponent } from './calendar.component';
import { DlgFuncDirective } from './dlg/dlg-func.directive';
import { DlgFuncService } from './dlg/dlg-func.service';
import { EventAddDlgBasicComponent } from './dlg/event-add-dlg-basic.component';
import { EventCancelDlgComponent } from './dlg/event-cancel-dlg.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QRCodeDlgComponent } from './dlg/qrcode-dlg.component';
import { WarningDlgComponent } from './dlg/warning-dlg.component';
import { TranslateModule } from '@ngx-translate/core';
import { SlideFuncService } from './slide/slide-func.service';
import { AlertSlideComponent } from './slide/alert-slide.component';
import { SlideFuncDirective } from './slide/slide-func.directive';
import { LocalConfigSlideComponent } from './slide/local-config-slide.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UICompModule } from '../ui-comp/ui-comp.module';
import { PincodeAuthDlgComponent } from './dlg/pincode-auth-dlg.component';
import { AdvanceSlideComponent } from './slide/advance-slide.component';
import { AutofocusDirective } from '../directive/autofocus.directive';
import { OnlyNumberDirective } from '../directive/onlyNum.directive';
import { CalendarContentModule } from './content/calendar-content.module';
import { EventAddDlgDemo1Component } from './dlg/event-add-dlg-demo1.component';

@NgModule({
  declarations: [
    CalendarComponent,
    EventAddDlgBasicComponent,
    EventAddDlgDemo1Component,
    EventCancelDlgComponent,
    QRCodeDlgComponent,
    WarningDlgComponent,
    PincodeAuthDlgComponent,
    AlertSlideComponent,
    LocalConfigSlideComponent,
    AdvanceSlideComponent,

    AutofocusDirective,
    OnlyNumberDirective,
    SlideFuncDirective,
    DlgFuncDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ServiceWorkerModule,
    CustomPipeModule,
    FontAwesomeModule,
    QRCodeModule,
    UICompModule,
    CalendarContentModule,
    TranslateModule.forChild({
      extend: true
    })
  ],
  providers: [
    DatePipe,
    DlgFuncService,
    SlideFuncService
  ],
  exports: [
    CalendarComponent
  ]
})
export class CalendarModule { }

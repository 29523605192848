import { Component } from '@angular/core';
import { CalendarAction, IAEventInfo } from '../lib/calendar.data';
import { IDlgFuncComponent } from './dlg-func.data';

@Component({
    templateUrl: './event-cancel-dlg.component.html'
})
export class EventCancelDlgComponent implements IDlgFuncComponent<{ event: IAEventInfo, actionName: string }, IAEventInfo> {
    title: string;
    action: CalendarAction;
    data?: { event: IAEventInfo, actionName: string };
    onApprove: (action: CalendarAction, data?: IAEventInfo) => void;
    onReject: (action: CalendarAction, data?: IAEventInfo) => void;

    confirm(): void {
        this.onApprove(this.action, this.data.event);
    }

    cancel(): void {
        this.onReject(this.action, this.data.event);
    }
}